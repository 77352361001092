import { client } from 'lib/evi-api-gateway';

const url = `${process.env.REACT_APP_API_URL}/school`;

// 首页数据，logo，banner，title等
export async function getSchoolInfo({ schoolCode }) {
  return client.post({
    url: `${url}/get_school_info`,
    form: {
      school_code: schoolCode,
      // lang,
    },
  });
}

// token
export async function getSchoolToken(params) {
  return client.post({
    url: `${process.env.REACT_APP_API_URL}/ns_user/decode_action_token`,
    form: {
      ...params,
    },
  });
}

export default {
  getSchoolInfo,
  getSchoolToken
};
