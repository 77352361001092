import { createSlice } from '@reduxjs/toolkit';

import * as formTask from 'api/formTask';

const initialState = {
  isLoading: false,
  error: null,
  taskList: {
    rows: [],
    total: -1,
  },
  submitResponse: null,
};

const slice = createSlice({
  name: 'formTask',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setTaskList(state, action) {
      state.isLoading = false;
      state.taskList = action.payload;
    },

    setSubmitResponse(state, action) {
      state.isLoading = false;
      state.submitResponse = action.payload;
    },
  },
});

// Actions

export default slice.reducer;

// get form task list data
export function fetchFormTaskList(params) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await formTask.getFormTaskList(params);
      // console.log('response', response);
      dispatch(slice.actions.setTaskList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// get ns user form data
export function submitNsUserForm(params) {
  return async (dispatch) => {
    const { onError, callback } = params;
    try {
      dispatch(slice.actions.startLoading());
      const response = await formTask.submitNsUserForm(params);
      // console.log('response', response);
      if (callback) {
        callback(response);
      }
      dispatch(slice.actions.setSubmitResponse(response));
    } catch (error) {
      onError(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
