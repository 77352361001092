/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import React, { useEffect, lazy } from 'react';
import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import { useRole, useRoleGroup } from 'lib/evi-role-action/hoc';
import { useTranslation } from 'react-i18next';

// import GuestGuard from '../guards/GuestGuard';
// import AuthGuard from '../guards/AuthGuard';

import Loadable from './Loadable';

const langComparison = {
  zh: 'zh',
  english: 'en',
  cn: 'cn',
};

const Routes = () => {
  const { i18n } = useTranslation();

  const [UserRole] = useRole({
    type: 'staff',
    module: 'user',
    controller: 'staff',
    action: [
      'get',
      'get_list',
      'add',
      'update',
      'delete',
      'add_teacher',
      'add_student',
    ],
  });

  const [GroupRole] = useRole({
    type: 'staff',
    module: 'user',
    controller: 'group',
    action: ['get_list', 'get'],
  });

  const [DrpcUserRole] = useRole({
    type: 'staff',
    module: 'drpc_user',
    controller: 'main',
  });

  const [BuilderRole] = useRole({
    type: 'staff',
    module: 'user',
    controller: 'builder',
  });

  const [DocumentRole] = useRole({
    type: 'staff',
    module: 'drpc_course',
    controller: 'document',
  });

  const [MerchantM] = useRoleGroup({
    type: 'staff',
    group: 'STAFF_ROLE_SUB_MERCHANT',
  });

  const [BuilderM] = useRoleGroup({
    type: 'staff',
    group: 'STAFF_BUILDER_ADMIN',
  });

  const [ContractM] = useRoleGroup({
    type: 'staff',
    group: 'STAFF_ROLE_CONTRACT_ADMIN',
  });

  const currentLang = localStorage.getItem('i18nextLng');

  return useRoutes([
    {
      path: '/',
      element: <RedirectGuard />,
      children: [
        {
          path: ':lang',
          element: <Main />,
          children: [
            { path: 'applications/view', element: <Viewer /> },
            { path: 'applications/receipt', element: <Receipt /> },
            {
              path: 'applications',
              element: <MyStepper />,
              children: [
                { path: 'list', element: <StepOne /> },
                { path: 'user', element: <StepTwo /> },
                { path: 'apply', element: <StepThree /> },
                { path: 'apply/:app_id', element: <StepFour /> },
                { path: 'apply/:app_id/confirm', element: <Confirm /> },
                { path: 'success', element: <StepFive /> },
                { path: 'payment', element: <Payment /> },
                { path: 'complete', element: <Complete /> },
                { path: 'complete_register', element: <CompleteRegister /> },
                { path: 'cancel', element: <Cancel /> },
              ],
            },
            { path: 'token/invalid', element: <AccessWithToken /> },
            { path: 'token/:token', element: <AccessWithToken /> },
            {
              path: 'profile',
              element: <ChangeProfile />,
            },
            {
              path: 'profile/detail',
              element: <DetailPrint />,
            },
            {
              path: 'accept-result',
              element: <AcceptResult />,
            },
            {
              path: 'form_task',
              element: <FormTaskList />,
            },
            {
              path: 'form_task/:display_id',
              element: <FormTaskGeneration />,
            },
            {
              path: 'form_task/:display_id/confirmation',
              element: <FormTaskConfirmation />,
            },
            {
              path: 'interview',
              element: <Interview />,
            },
            {
              path: 'interview-time',
              element: <InterviewTime />,
            },
            {
              path: 'interview-success',
              element: <Success />,
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to={`/${langComparison[i18n.language]}`} replace />,
    },
  ]);
};

// const Login = Loadable(lazy(() => import('pages/login')));
const RedirectGuard = Loadable(lazy(() => import('../guards/RedirectGuard')));
const Main = Loadable(lazy(() => import('layout')));
const MyStepper = Loadable(lazy(() => import('pages/myStepper')));
const ChangeProfile = Loadable(lazy(() => import('pages/changeProfile')));
const DetailPrint = Loadable(
  lazy(() => import('pages/changeProfile/DetailPrint'))
);
const StepOne = Loadable(lazy(() => import('pages/myStepper/StepOne')));
const StepTwo = Loadable(lazy(() => import('pages/myStepper/StepTwo')));
const StepThree = Loadable(lazy(() => import('pages/myStepper/StepThree')));
const StepFour = Loadable(lazy(() => import('pages/myStepper/StepFour')));
const StepFive = Loadable(lazy(() => import('pages/myStepper/StepFive')));
const Payment = Loadable(lazy(() => import('pages/myStepper/Payment')));
const Cancel = Loadable(lazy(() => import('pages/myStepper/Cancel')));
const AccessWithToken = Loadable(lazy(() => import('pages/AccessWithToken')));
const Viewer = Loadable(lazy(() => import('pages/Viewer')));
const Receipt = Loadable(lazy(() => import('pages/receipt')));
const Confirm = Loadable(lazy(() => import('../components/home/Confirmation')));
const Complete = Loadable(lazy(() => import('pages/myStepper/Complete')));
const CompleteRegister = Loadable(
  lazy(() => import('pages/myStepper/CompleteRegister'))
);
const AcceptResult = Loadable(lazy(() => import('pages/AcceptResult')));
const FormTaskList = Loadable(
  lazy(() => import('pages/formTask/FormTaskList'))
);
const FormTaskGeneration = Loadable(
  lazy(() => import('pages/formTask/FormTaskGeneration'))
);
const FormTaskConfirmation = Loadable(
  lazy(() => import('pages/formTask/FormTaskConfirmation'))
);
const Interview = Loadable(lazy(() => import('pages/interview')));
const InterviewTime = Loadable(
  lazy(() => import('pages/interview/InterviewTime'))
);
const Success = Loadable(lazy(() => import('pages/interview/Success')));
export default Routes;
