const config = {
    select_time: '你选择的面见时间',
    please_select: '面试资料',
    interview_date: '面见时间',
    interview_stu: '面见学生名称',
    interview_name: '面见校舍名称',
    Application_No: '申请编号',
    print: '列印',
    go_home: '返回主页',
    confirm_date: '确定面见时间',
    reselect: '重新选择',
    confirm: '确定',
    // Gabriel End Here 29-May-2023
};
export default config;
