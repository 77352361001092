import { useState } from 'react';
import { useSelector } from 'react-redux';
import * as _ from 'underscore';

const useRoleGroup = ({ group, type = '', role_list }) => {
  const { role_group_list } = useSelector(
    (state) => (state && state[type]) || []
  );
  const name = (
    (group instanceof Object ? group.group : group) || ''
  ).toUpperCase();
  const [roleGroup, setRoleGroup] = useState(
    !!group &&
      (role_list ? role_list : role_group_list || []).indexOf(group) > -1
  );
  return [roleGroup, setRoleGroup];
};

export default useRoleGroup;
