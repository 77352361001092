import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  access_token: null,
  isLoading: false,
  error: { result: null, msg: null },
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export function logout() {}

export default slice.reducer;
