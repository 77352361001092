const config = {
    applicationFeeReceipt: '报名费收据',
    school_name: '申请学校',
    HongKongSokaKindergarten: '香港创价幼稚园',
    name: '姓名',
    applicationNo: '申请编号',
    gradeapplied: '申请年级',
    amount: '金额',
    status: '状态',
    print: '列印',
    PaymentMethod: '付款方式'
    // Gabriel End Here 29-May-2023
};
export default config;
