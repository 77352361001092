/* eslint-disable camelcase */
import { client } from 'lib/evi-api-gateway';

const url = `${process.env.REACT_APP_API_URL}/interview`;

// 获取面试时间列表
export async function getInterviewTime({ schoolAppId, phaseId, appGradeId }) {
  return client.post({
    url: `${url}/get_interview_time`,
    form: {
      school_app_id: schoolAppId,
      phase_id: phaseId,
      app_grade_id: appGradeId,
    },
  });
}

// 获取用户选择的面试时间
export async function getInterviewUserList({ params }) {
  return client.post({
    url: `${url}/get_interview_user_list`,
    form: {
      ...params,
    },
  });
}

// 新增或者修改用戶的面試時間
export async function addInterviewUser(params) {
  return client.post({
    url: `${url}/add_interview_user`,
    form: {
      ...params,
    },
  });
}

export default {
  getInterviewTime,
  getInterviewUserList,
  addInterviewUser,
};
