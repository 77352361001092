/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
// api
import { getSchoolAppYear } from '../../api/appMaster';
import { getInterviewTime, getInterviewUserList } from '../../api/interview';

// const getStep = () => {
//   const stepNum = localStorage.getItem('step') * 1 || 0;
//   if (stepNum > 5 || stepNum < 0) return 0;
//   return stepNum;
// };

const initStepInfo = {
  // 选择的申请年度
  master_app_id: '',
  // 选择的申请年度名称
  master_app_name: '',
  // 驗證資料
  cert_type: 1,
  cert_id: '',
  birthday: '',
  email: '',
  // 选择学校及幼儿园
  school_app_id: '',
  app_grade_id: '',
  grade_id: '',
  grade_name: '',
  school_name: '',
  bank_slip: '',
  districtsub: '',
};

const initialState = {
  isLoading: false,
  error: false,
  // 当前的步骤数
  // activeStep: getStep(),
  activeStep: 0,
  // 标识有无分校
  isSubSchool: '',
  // step获取的信息
  // stepInfo: JSON.parse(localStorage.getItem('stepInfo')) || { ...initStepInfo },
  stepInfo: initStepInfo,
  // 报读幼儿园列表
  gradeList: [],
  // 是否是更改模式
  updateMode: false,
  // 是否是只读模式
  readMode: false,
  // 由后台打开,更改模式或者只读模式都可展示‘储存’按钮
  showSubmit: false,
  estimate: null,

  // 面试时间列表
  interviewTimeList: [],
  timeListLoading: false,
  isShowViewTime: false,
  // 面试时间提交数据
  timeIdList: [],

  // first time_id
  firstTimeId: {},

  // 是否验证邮箱
  isVerify: false,
  // 从确认页到报名表的标志（为grade_description所用）
  flag: false,
};

const slice = createSlice({
  name: 'step',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    inintStepState(state, { payload }) {
      // 步骤条步数
      state.activeStep = 0;
      const { cert_type, cert_id, birthday, email } = state.stepInfo;
      // 如果用户登录则保留登录信息
      state.stepInfo = payload
        ? { ...initStepInfo, cert_type, cert_id, birthday, email }
        : { ...initStepInfo };
      // localStorage.setItem('step', state.activeStep);
      // localStorage.setItem('stepInfo', JSON.stringify(state.stepInfo));
    },
    resetStepInfo(state) {
      state.stepInfo = initStepInfo;
      state.timeIdList = [];
      state.interviewTimeList = [];
      state.activeStep = 0;
      state.isVerify = false;
      state.firstTimeId = {};
    },
    handleStepNext(state) {
      state.activeStep += 1;
      // localStorage.setItem('step', state.activeStep);
    },
    handleStepBack(state) {
      state.activeStep -= 1;
      // localStorage.setItem('step', state.activeStep);
    },
    // 直接进入選擇報讀的幼稚園页面
    handleStepThree(state) {
      state.activeStep = 2;
      // localStorage.setItem('step', state.activeStep);
    },
    // 直接进入完成页
    enterStepFinish(state) {
      state.activeStep = 6;
    },
    // 直接进入請填寫入學報名表格页面
    handleStepFour(state) {
      state.activeStep = state.stepInfo.action !== 'add' ? 3 : 2;
      // localStorage.setItem('step', state.activeStep);
    },
    // 在“更改模式”下进入填寫入學報名表格页面
    enterStepFour(state) {
      state.activeStep = state.stepInfo.action !== 'add' ? 3 : 2;
      state.updateMode = true;
    },
    // 取消‘更改模式’
    cancelUpdateMode(state) {
      state.updateMode = false;
    },
    // 取消‘只读模式’
    cancelReadMode(state) {
      state.readMode = false;
    },
    // 锁定支付页面
    handleStepFive(state) {
      state.activeStep = state.stepInfo.action !== 'add' ? 4 : 3;
      // localStorage.setItem('step', state.activeStep);
    },
    // 完成页面
    handleStepSix(state) {
      state.activeStep = state.stepInfo.action !== 'add' ? 5 : 4;
    },
    // 保存stepInfo
    setStepInfo(state, action) {
      state.stepInfo = { ...state.stepInfo, ...action.payload };
    },
    // 获取幼儿园列表
    getGradeListSuccess(state, action) {
      state.isLoading = false;
      state.gradeList = action.payload;
    },
    getEstimate(state, action) {
      state.isLoading = false;
      state.estimate = action.payload;
    },
    useToken(state, action) {
      const { callback } = action.payload;
      state.activeStep = action.payload.step;
      state.stepInfo = { ...state.stepInfo, ...action.payload.info };
      state.updateMode = action.payload.updateMode || state.updateMode;
      state.readMode = action.payload.readMode || state.readMode;
      state.showSubmit = action.payload.showSubmit || state.showSubmit;
      if (callback) {
        callback();
      }
    },
    setTimeLoading(state, action) {
      state.timeListLoading = action.payload;
    },
    getInterviewTimeList(state, action) {
      state.timeListLoading = false;
      state.isShowViewTime = true;
      state.interviewTimeList = action.payload;
    },
    getInterviewTimeListError(state) {
      state.timeListLoading = false;
      state.isShowViewTime = false;
    },
    setTimeIdList(state, action) {
      state.timeIdList = action.payload;
    },
    setFirstTimeId(state, action) {
      state.firstTimeId = action.payload;
    },
    setIsShowViewTime(state, action) {
      state.isShowViewTime = action.payload;
    },
    setIsVerify(state, action) {
      state.isVerify = action.payload;
    },
    setFlag(state, action) {
      state.flag = action.payload;
    },
  },
});

// Actions
export const {
  handleStepNext,
  handleStepBack,
  inintStepState,
  resetStepInfo,
  handleStepThree,
  handleStepFour,
  handleStepFive,
  handleStepSix,
  enterStepFour,
  setStepInfo,
  cancelUpdateMode,
  cancelReadMode,
  useToken,
  enterStepFinish,
  setTimeIdList,
  setIsShowViewTime,
  setIsVerify,
  setFlag,
} = slice.actions;

export default slice.reducer;

export function getGradeListAction(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getSchoolAppYear(params);
      dispatch(slice.actions.getGradeListSuccess(response.rows));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function judgeRouter(params) {
  return async (dispatch) => {
    try {
      const response = params;
      // console.log(response);
      dispatch(slice.actions.getEstimate(response.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// 获取面试时间列表
export function featchInterviewTimeList(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setTimeLoading(true));
    try {
      const response = await getInterviewTime(params);
      dispatch(slice.actions.getInterviewTimeList(response.rows));
    } catch (error) {
      dispatch(slice.actions.getInterviewTimeListError());
    }
  };
}
// 获取选中面试用户信息列表
export function featchInterviewUserList(params) {
  return async (dispatch) => {
    try {
      const response = await getInterviewUserList({ params });
      const timeIdList = response.map((item) => {
        const { time_id, phase_id, cert_id, cert_type, date, starttime } = item;
        return {
          cert_id,
          cert_type,
          time_id: `${time_id},${phase_id}`,
          date,
          starttime,
        };
      });
      dispatch(slice.actions.setTimeIdList(timeIdList));
      dispatch(
        slice.actions.setFirstTimeId(
          response.reduce(
            (acc, cur) => ({
              time_id: cur.time_id ? cur.time_id.split(',')[0] : 'null',
              date: cur.date,
              starttime: cur.starttime,
            }),
            {}
          )
        )
      );
    } catch (error) {
      console.log(error);
    }
  };
}
