const config = {
  welcome_online_apply_system: 'Welcome to the Online Application System',
  online_appliy_form: 'Online Application Form',
  change_print_data: 'Review/Change Information',
  common_problem: 'FAQ',
  lang: 'English',
  set_lang: 'Language Setting',
  zh: 'Traditional Chinese',
  cn: 'Simplified Chinese',
  english: 'English',
  support_phone: 'Hotline: ',
  notice: 'Notifications',
  notice_total: 'You have {{totalUnRead}} unread message(s).',
  msg_sccuss: 'Deleted Successfully',
  no_admission_form: 'No application forms',
  preview: 'Document Preview',
  accept_result: 'We have kindly received your reply.',
  // Gabriel End Here 29-May-2023
  veo_copyRight: 'Victoria Educational Organisation. All Rights Reserved.',
};
export default config;
