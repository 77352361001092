import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import ekinderLang from './ns_dekinder_hk_lang';
import plkkgsLang from './plkkgs_lang';
import veoLang from './veo_lang';

// import zh from './ns_dekinder_hk_lang/zh';
// import cn from './ns_dekinder_hk_lang/cn';
// import english from './ns_dekinder_hk_lang/en';

// const resources = {
//   zh,
//   cn,
//   english,
// };

const currentEnvHost = process.env.REACT_APP_HOST;

const resourcesList = [
  {
    host: 'ns.dekinder.hk',
    resources: ekinderLang,
  },
  {
    host: 'plkkgs',
    resources: plkkgsLang,
  },
  {
    host: 'veo',
    resources: veoLang,
  },
];

const currentSetting = resourcesList.find(
  (item) => item.host === currentEnvHost
);

// console.log(currentSetting);

i18n
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: currentSetting
      ? currentSetting.resources
      : resourcesList[0].resources,
    lng: localStorage.getItem('i18nextLng') || 'zh',
    fallbackLng: 'zh',
    debug: true,
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i'],
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
