import { client } from 'lib/evi-api-gateway';

const url = `${process.env.REACT_APP_API_URL}/form`;

export async function getFormList(params) {
  return client.post({
    url: `${url}/get_form_item`,
    form: { ...params },
  });
}

export async function getForm(params) {
  return client.post({
    url: `${url}/get_form`,
    form: { ...params },
  });
}

export async function submitForm(params) {
  return client.post({
    url: `${url}/submit`,
    form: { ...params },
  });
}
export async function saveTemeForm(params) {
  return client.post({
    url: `${url}/temp_save`,
    form: { ...params },
  });
}
export async function getAddressList(params) {
  return client.post({
    url: `${url}/get_address_list`,
    form: { ...params },
  });
}

// get form task data
export async function getSubForm(params) {
  return client.post({
    url: `${process.env.REACT_APP_API_URL}/form_task/get_form`,
    form: { ...params },
  });
}
export default {
  getFormList,
  submitForm,
  getSubForm,
  getAddressList,
};
