import { useState } from 'react';
import { useSelector } from 'react-redux';
import * as _ from "underscore";

const useRole = ({ action , module , controller , contract_id , type , value }) => {
  const { role_list } = useSelector( state => (state && state[type]) || [] );
  const _action = (action || ['get' , 'get_list' , 'delete' , 'update' , 'add']);

  const roleChecker = _action.map(_a => ({
    action : _a,
    contract_id : (contract_id || null),
    ctrl : controller ,
    module : module,
    type : type.toUpperCase(),
    value : value || null,
  }));

  const actionRole = roleChecker.filter(
    role => (role_list || []).filter( _role => _.isEqual(_role , role) ).length > 0
  );

  const [role , setRole] = useState(_action.reduce((acc , cur , idx) => {
    acc[cur] = (actionRole.filter(role => role.action === cur).length > 0)
    return acc;
  } , {}));

  return [ role , setRole ];
};

export default useRole;
