/* eslint-disable quotes */
const config = {
  enter_register_form: 'Fill out the Application Form',
  Confirm_data: 'Confirmation',
  Choose_payment_method: 'Choose Payment Method',
  Choose_child: 'Select Child',
  admission_date: 'Entry Date',
  next_step: 'Next',
  last_step: 'Back',
  go_back: 'Back',
  previous_page: 'Back',
  confirm: 'Confirm',
  first_party: 'Part A: Information for Child who apply',
  party_B: 'Part B: Information for Parent/Guardian',
  party_C: 'Part C: Other Information',
  apply_student_info: 'Information for Student Applying',
  mandatory: '* Required',
  please_select: '--Please select--',
  pleas_input: 'Please enter....',
  district_select: 'District',
  graduate: 'Brothers or sisters who graduated from our kindergarten:',
  Yes: 'Yes',
  No: 'No',
  relationship: 'Relationship with Applicant:',
  select: 'Please Select',
  other: 'Other',
  Chinese_name: 'Chinese Name',
  Year_of_graduation: 'Year of Graduation: ',
  newly_added: 'Add brothers or sisters who are studying in our school',
  meanwhile: 'Siblings apply simultaneously',
  Number: 'HK Birth Certificate Number',
  Apply_reading: 'Grade Applied For: ',
  new: 'Add brothers or sisters graduated from this kindergarten',
  multiple: 'Are there twins/multiples applied simultaneously?',
  English_name: 'English Name',
  Gender: 'Gender',
  Man: 'Male',
  Woman: 'Female',
  date_of_birth: 'Date of Birth',
  Place_of_birth: 'Place of Birth',
  Type_birth: 'Type of Birth Certificate',
  Certificate: 'HK Birth Certificate',
  serial: 'Birth Certificate Registration Number',
  copy: 'Copy of Birth Certificate',
  Effective:
    'Valid passport and Hong Kong Visa(for non Hong Kong born applicant):',
  least: "* Fill out at least 1 Guardian's Information",
  Add_another_Guardian: 'Add another Guardian',
  Applied_school: 'School Applied For: ',
  Academic_year: 'Academic Year Applied For:',
  Application_grade: 'Grade Applied for: ',
  Statement: 'Personal Data Collection Declaration',
  objective: 'Data Collection Purpose: ',
  provide: 'Information to be Provided:',
  term: 'Data Retention Period: ',
  Transfer: 'Transfer of Data: ',
  Rights: 'Rights of Applicants:',
  declaration:
    'I have read and accepted the above Personal Data Collection Declaration',
  latest_photo: "Applicant's Recent Photo",
  twin: 'Twin/Multiple Birth Information:',
  address: 'Address',
  Area: 'District',
  nationality: 'Nationality',
  Commonly_used: 'Common language used at home',
  Email_address: 'Email address',
  Contact_number: 'Contact Number',
  School: 'School Attended',
  Class: 'Class Attended',
  Volunteer: 'Class-Type Choice',
  Full: 'When the class-type applied is full, the school will allocate with available class-types.',
  twin_Chinese_name: 'Chinese Name of Twin/Multiple',
  twin_English_name: 'English Name of Twin/Multiple',
  twin_Gender: 'Gender of Twin/Multiple',
  twin_date_of_birth: 'Birth Date of Twin/Multiple',
  twin_Place_of_birth: 'Birth Place of Twin/Multiple',
  Father_information: "Father's Information",
  Mother_information: "Mother's Information",
  Orther_information: "Guardian's Information",
  Mobile_phone: 'Mobile phone',
  Occupation: 'Occupation',
  Graduates: 'Is a graduate of this school?',
  Year_graduation: 'Year of Graduation',
  Staff: 'Is a full-time employee of the school?',
  Cname: 'Chinese Name',
  attend_school: 'Brother(s) and Sister(s) studying in this school',
  Graduated: 'Brother(s) and sister(s) graduated from this school',
  Apply_for_reading:
    'There is(are) brother(s) and sister(s) applied simultaneously.',
  Study_kindergarten:
    'There is(are) brother(s) and sister(s) studying in this school.',
  Relationship: 'Relationship with Applicant',
  Year_study: 'Year Period of Study',
  Graduation: 'Brother(s) and sister(s) is(are) graduates of this school.',
  team: 'Class',
  In_operation: 'Processing....',
  Later: 'Payment page will be presented, please wait....',
  Application_No: 'Application Number',
  Name_applicant: 'Name of Applicant',
  Registration_fee: 'Application Fee',
  payment_method: 'Payment Method',
  Count_paper: 'Upload Receipt',
  Input_data: 'Bank Transfer Info',
  Bank_name: 'Bank Name',
  Account_name: 'Account Name',
  Account_number: 'Account Number',
  Upload_attachments: 'Upload Attachments',
  read: "The applicant's brother(s)/sister(s) will apply too: ",
  reading: 'Add brother(s)/sister(s) will apply too:',
  least_one: "Fill in at least 1 Guardian's Information",
  upload_err: 'Upload Failure',
  save_progress: 'Save',
  save_suc: 'Saved Successfully',
  save_err: 'Failed to Save',
  status: 'Payment Status',
  cannot_yet: 'Unpaid',
  paid_suc: 'Paid',
  is_all_payment: 'Payment is completed and returns to the Main Page.',
  leave: 'Go Payment Page',
  look_over: 'View Information',
  personage: 'View Personal Data Collection Declaration',
  ont_OK: 'I confirm that the information is correct',
  accept: 'I have read and accepted the Personal Data Collection Declaration',
  WHOLE: 'Whole-Day Class',
  AM: 'AM Class',
  PM: 'PM Class',
  session_1: 'Session 1:',
  session_2: 'Session 2:',
  session_3: 'Session 3:',
  session_4: 'Session 4:',
  session_5: 'Session 5:',
  session_6: 'Session 6:',
  session_7: 'Session 7:',
  session_8: 'Session 8:',
  session_9: 'Session 9:',
  session_10: 'Session 10:',
  volunteer: 'Session',
  payment: 'Pay',
  submit: 'Submit',
  objectiveDate:
    'Applicants must provide the required personal information in the field marked (*). Failure to do so may result in the school being unable to process the application.',
  provideDate:
    'If an application is not accepted, all personal information will be destroyed. The personal information of successful applicants will be placed in the students database and kept by the school.',
  termDate:
    'The application of unsuccessful candidates will be destroyed. The application of successful candidates will become part of the student file, in connection with this data will thereafter be handled by the kindergarten.',
  TransferDate:
    'Under the Personal Data (Privacy) Ordinance, the applicant has the right of access and correction to the information provided in the application and may obtain a copy of such information. Applicants who require access to or correction of their personal data should inform the School Officer by letter.',
  drag: '* Please drag the box for sorting.',
  Upload_files: 'Upload Files',
  room: 'Room',
  floor: 'Floor',
  seat: 'Block',
  house: 'Name of Building/Estate',
  street: 'Street Name & Number',
  area: 'District',
  meanwhiles: 'Simultaneous Application of Twins/Multiples',
  additional: 'New',
  delete: 'Delete',
  Father_data: "Father's Data",
  Mother_data: "Mother's Data",
  Add_other: 'Add Other Guardians',
  others: "Other Guardian's Information",
  or: "The applicant's parents or siblings are graduates of the school",
  graduate_fa: "The applicant's father is a graduate of this school.",
  graduate_ma: "The applicant's mother is a graduate of this school.",
  graduate_or: "The applicant's guardian is a graduate of this school",
  employee_fa: "The applicant's father is a full-time employee of this school.",
  employee_ma: "The applicant's mother is a full-time employee of this school.",
  employee_or:
    "The applicant's guardian is a full-time employee of this school",
  relation: 'Relationship with  pplicants',
  verify: 'Confirm and Pay',
  return_home: 'Return to Home',
  paymented: 'View/Change Information',
  payment_cancel: 'Cancel Payment',
  payment_again: 'Re-pay',
  apply_success: 'Your application has been submitted successfully.',
  archive_no: 'Your Application Number is ',
  Confirm_notification:
    'You can use your application information to log in to the "check or change details" on the main page. ',
  checked_details:
    'You can log in to the home page with your registered applicant information and click on "View/Change Information" to check your progress and perform other actions.',
  tip: 'Precautionary measures: If you cannot receive a confirmation email from us, you can click on the "Frequently Asked Questions (FAQ)" at the top right corner of the interface for solutions.',
  paid: 'Payment Success',
  elder_brother: 'Elder Brother',
  elder_sister: 'Elder Sister',
  require: 'Reuqired',
  hk_id_card: 'Please enter the correct Birth Certificate registration number',
  room_required: 'The room cannot be empty.',
  floor_required: 'The floor cannot be empty.',
  block_required: 'The block cannot be empty.',
  village_required: 'The building/estate name cannot be empty.',
  area_required: 'The area cannot be empty.',
  street_required: 'The street name and number cannot be empty.',
  district_required: 'The region cannot be empty.',
  class_required: 'Please drag the blocks to sort your choices.',
  email_error: 'The email address entered is inconsistent, please re-enter!',
  email_required: 'Please re-enter the email address',
  isMutiple: 'Please select if you have twins/multiples applied simultaneously',
  Central_Western: 'Central and Western District',
  Eastern: 'Eastern District',
  Island: 'Island District',
  Kowloon_City: 'Kowloon City District',
  Kwai_Tsing: 'Kwai Tsing District',
  Kwun_Tong: 'Kwun Tong District',
  North: 'North District',
  Sai_Kung: 'Sai Kung District',
  Sha_Tin: 'Sha Tin District',
  Sham_Shui_Po: 'Sham Shui Po District',
  Southern: 'Southern District',
  Tai_Po: 'Tai Po District',
  Tsuen_Wan: 'Tsuen Wan District',
  Tuen_Mun: 'Tuen Mun District',
  Wan_Chai: 'Wan Chai District',
  Wong_Tai_Sin: 'Wong Tai Sin District',
  Yau_Tsim_Mong: 'Yau Tsim Mong District',
  Yuen_Long: 'Yuen Long District',
  first_verify_email:
    'The system needs to verify your registered email address.',
  verify_code_send: 'The Verification Code is sent to ',
  input_verify_code: 'Please enter the Verification Code',
  verify_email: 'Verify',
  cancel: 'Cancel',
  no_email_received: "Can't receive email?",
  send_again: 'Re-send',
  obsolete_failure: 'Validation Code Expired',
  verify_code_error: 'Incorrect Verification Code',
  email_tip:
    'After verification by email, if you have not completed your application, you can select "Online Application Form" from the home page and enter your previous basic registration information to view your saved application information and continue the application process.',
  'parent.is_school_graduate': 'Is graduate of this school?',
  'parent.is_school_employee': 'Is full-time employee of this school?',
  voluteer_agreement:
    'I agree to leave the above option blank to indicate that the Class Type will not be considered.',
  err: 'Please check the box.',
  no_result: 'No results',
  select_address: 'Please select your address.',
  enterMore: 'Please enter more keywords to get more accurate results.',
  IsToRead: 'Is Applying',
  print: 'Print',
  Nooptions: 'No records',
  select_view_time: 'Choose Interview Time',
  date_time: 'Interview Date & Time',
  change_lang: 'Change Interview Time',
  selected: 'Selected',
  confirm_time: 'Confirm',
  file_larger: 'The file size cannot exceed ',
  type_error: 'Incorrect File Format',
  login_err: 'Login Verification Failure',
  submit_err: 'Data Submission Failure',
  submit_err_211_212: 'You cannot apply twice in same application',
  submit_err_220: 'Application submission time has ended',
  submit_err_300: 'Interview slots are full.',
  file_size: 'The file size cannot exceed {{size}}MB',
  file_accept: 'Accepted File Format: {{accept}}',
  sort_1: '1st Choice',
  sort_2: '2nd Choice',
  sort_3: '3rd Choice',
  sort_4: '4th Choice',
  sort_5: '5th Choice',
  sort_6: '6th Choice',
  sort_7: '7th Choice',
  sort_8: '8th Choice',
  sort_9: '9th Choice',
  sort_10: '10th Choice',
  change_suc: 'Updated Successfully',
  change_fail: 'Failed to Update',
  no_record: 'No records',
  stored: 'Saved',
  storage: 'Save',
  // Gabriel End Here 29-May-2023
  accept_left: 'I have read',
  accept_right: 'the collection of personal Information statement',
  delete_tip: 'Do you need to re-upload the file?',
  yes: 'Yes',
  no: 'No',
  district: 'District :',
  submit_err_213: 'Duplicate No. of Birth Certificate/Passport/Other',
  too_long: 'Image width or height is greater than 4000px',
};
export default config;
