const config = {
    welcome_online_apply_system: '欢迎使用网上入学申请系统',
    online_appliy_form: '网上入学申请表',
    change_print_data: '查阅/更改资料',
    common_problem: '常见问题',
    lang: '繁中',
    set_lang: '语言设定',
    zh: '繁体中文',
    cn: '简体中文',
    english: '英文',
    support_phone: '支援热线：',
    notice: '讯息中心',
    notice_total: '你有{{totalUnRead}}个新讯息',
    msg_sccuss: '删除成功',
    no_admission_form: '没有报名表',
    preview: '文件浏览',
    accept_result: '我们已收到您的回覆',
    // Gabriel End Here 29-May-2023
};
export default config;
