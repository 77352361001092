import { client } from 'lib/evi-api-gateway';

const url = `${process.env.REACT_APP_API_URL}`;

// 获取我的信息
export async function getUserMe() {
  return client.post({
    url: `${url}/user/me`,
  });
}
// 查看用户面试信息
export async function getInterviewUser({ userId, schoolAppId }) {
  return client.post({
    url: `${url}/interview/get_interview_user`,
    form: {
      user_id: userId,
      school_app_id: schoolAppId,
    },
  });
}

// 获取用户的消息通知
export async function getMsgList({ schoolAppId, limit = 10, offset = 0 }) {
  return client.post({
    url: `${url}/user/get_msg_list `,
    form: {
      school_id: schoolAppId,
      limit,
      offset: offset * limit,
    },
  });
}

// 获取用户的消息通知
export async function updateMsgList({ id, action }) {
  return client.post({
    url: `${url}/user/update_msg `,
    form: {
      id,
      action,
    },
  });
}

export default {
  getUserMe,
  getInterviewUser,
  getMsgList,
};
