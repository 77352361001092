const config = {
    select_time: '你選擇的面見時間',
    please_select: '面試資料',
    interview_date: '面見時間',
    interview_stu: '面見學生名稱',
    interview_name: '面見校舍名稱',
    Application_No: '申請編號',
    print: '列印',
    go_home: '返回主頁',
    confirm_date: '確認面見時間',
    reselect: '重新選擇',
    confirm: '確定',
    // Gabriel End Here 29-May-2023
};
export default config;
