const config = {
    applicationFeeReceipt: '報名費收據',
    school_name: '申請學校',
    HongKongSokaKindergarten: '香港創價幼稚園',
    name: '姓名',
    applicationNo: '申請編號',
    gradeapplied: '申請年級',
    amount: '金額',
    status: '狀態',
    print: '列印',
    PaymentMethod: '付款方式'
    // Gabriel End Here 29-May-2023
};
export default config;
