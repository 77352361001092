// ----------------------------------------------------------------------

const breakpoints = {
  values: {
    xs: 0,
    ss: 360,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    specificallyMd: 800,
  },
};

export default breakpoints;
