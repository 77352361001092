import login from './login';
import main from './main';
import step from './step';
import Agreement from './Agreement';
import ChangeForm from './ChangeForm';
import StepFour from './StepFour';
import StepFive from './StepFive';
import receipt from './receipt';
import interview from './interview';

const config = {
  login,
  main,
  step,
  Agreement,
  ChangeForm,
  StepFour,
  StepFive,
  receipt,
  interview,
};

export default config;
