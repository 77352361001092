const config = {
    know: '本人确认已阅读及明白以上有关网上办理新生入学申请之程序',
    next_step: '下一步',
    go_back: '返回',
    tip_next: '请剔选「本人已阅读及明白以上有关网上新生入学申请程序」',
    Conditions_overleaf: '网上报名程序：',
    program_1: '1. 请选择欲报读之属下幼稚园/幼儿园。',
    program_2:
    '2. 请填妥报名表，并将儿童「出生证明文件」及「最近三个月内之证件相片」上载到相关的位置。',
    program_3:
    '3. 报名费为＊港币$40元正(缴费方式∶(1)直接以Visa丶Master付款 或 (2)亲自到银行/自动柜员机入数，并将入数纸上载至报名系统)',
    program_3_1: '如选用银行转账，请存入属下幼稚园/幼儿园之指定银行户口',
    program_3_2: '如选用银行转账，必须保留入数纸存根，并於面见日交回校方以作核实',
    program_3_3: '＊保良局蔡冠深（泽富楼）幼儿园无须收取报名费',
    program_4:
    '4. 成功递交入学申请表後，系统会发出一封「确认入学申请电邮通知书」电邮，请不要回覆此电邮。如需查询，请于办公时间致电所报读之属下幼稚园/幼儿园。',
    program_5: ' 5. 请于面试当日带同以下资料：',
    program_5_1: '儿童出生证明书正本',
    program_5_2:
    '报名费入数纸存根之正本(适用于在网上系统作报名，并已上传报名费入数纸照片之申请者)',
    remark: '备注：',
    remark_1:
    '1. 申请学童必须是香港居民丶拥有香港居留权丶入境权或不附带任何逗留条件(逗留期限除外)的有效香港居留许可。',
    remark_2:
    '2. 报名费一经缴交，恕不退还，请先确认欲申请报读之属下幼稚园/幼儿园之银行户口号码。',
    remark_3:
    '3. 请先将此电邮地址(noreply@smtp.plkkgs.edu.hk)加入至联络人名单，以免系统所发出的邮件被置于垃圾邮件中。',
    // Gabriel End Here 29-May-2023
};
export default config;
