import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes';
// theme
import ThemeConfig from './theme';

import NotistackProvider from './components/NotistackProvider';
import './assets/index.css';

function App() {
  return (
    <ThemeConfig>
      <NotistackProvider>
        <BrowserRouter>
          <Router style={{ margin: '0' }} />
        </BrowserRouter>
      </NotistackProvider>
    </ThemeConfig>
  );
}

export default App;
