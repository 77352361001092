const config = {
    applicationFeeReceipt: 'Application Fee Receipt',
    school_name: 'School Name',
    HongKongSokaKindergarten: 'Hong Kong Soka Kindergarten',
    name: 'Name',
    applicationNo: 'Application Number',
    gradeapplied: 'Grade Applied For',
    amount: 'Amount',
    status: 'Status',
    print: 'Print',
    PaymentMethod: 'Payment Method'
    // Gabriel End Here 29-May-2023
};
export default config;
