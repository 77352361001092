import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { client } from 'lib/evi-api-gateway';

const initialState = {
    token: {},
    status: 'idle',
    error: null
}

const url = `${process.env.REACT_APP_API_URL}`;

export const validToken = createAsyncThunk('token/valid_token', async (token) => {
    const response = await client.post({
        url: `${url}/ns_user/decode_action_token`,
        form: {
            token
        }
    })
    return response;
})

const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
      setStatus(state, { payload }) {
        state.status = payload;
      }
    },
    extraReducers(builder) {
        builder
            .addCase(validToken.pending, (state) => {
                state.status = 'loading'
                state.token = initialState.token
            })
            .addCase(validToken.fulfilled, (state, action) => {
                state.status = 'success'
                state.token = action.payload
            })
            .addCase(validToken.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
});

export const { setStatus } = tokenSlice.actions;


export default tokenSlice.reducer;