const config = {
  enter_register_form: '填写入学报名表格',
  Confirm_data: '确认资料',
  Choose_payment_method: '选择付款方式',
  Choose_child: '选择幼儿',
  admission_date: '入数日期',
  next_step: '下一步',
  last_step: '上一步',
  go_back: '返回',
  previous_page: '上一页',
  confirm: '确定',
  first_party: '甲部 : 申请之幼儿资料',
  party_B: '乙部 : 父母/监护人资料',
  party_C: '丙部 : 其他资料',
  apply_student_info: '申请学生资料',
  graduate: '本园毕业的兄姊：',
  Yes: '是',
  No: '否',
  relationship: '与申请者关系：',
  select: '请选择',
  other: '其他',
  Chinese_name: '中文姓名',
  Year_of_graduation: '毕业年份：',
  newly_added: '新增正在本园就读之兄姊',
  meanwhile: '兄姊同时报读：',
  Number: '香港出生证明书编号',
  Apply_reading: '申请年级',
  new: '新增本园毕业之兄姊',
  mandatory: '*为必填项目',
  please_select: '--请选择--',
  pleas_input: '请输入....',
  district_select: '地区',
  multiple: '是否有双胞胎/多胞胎同时报名',
  English_name: '英文姓名',
  Gender: '性别',
  Man: '男',
  Woman: '女',
  date_of_birth: '出生日期',
  Place_of_birth: '出生地点',
  Type_birth: '出生证明书类型',
  Certificate: '香港出生证明书',
  serial: '出生证明书登记编号',
  copy: '出生证明书副本：',
  Effective: '有效护照及香港签证(非香港出生使用)：',
  least: '*最少填写一位监护人资料',
  Add_another_Guardian: '新增其他监护人',
  Applied_school: '申请学校：',
  Academic_year: '申请学年：',
  Application_grade: '申请年级：',
  Statement: '收集个人资料声明',
  objective: '收集资料的目的：',
  provide: '需提供的资料:',
  term: '资料的保存期限：',
  Transfer: '资料的转交：',
  Rights: '申请者权利：',
  declaration: '本人已阅读及接受以上有关收集个人资料声明',
  latest_photo: '学生近照',
  twin: '双胞胎/多胞胎资料：',
  address: '地址',
  Area: '地区',
  nationality: '国籍',
  Commonly_used: '在家常用语言',
  Email_address: '电邮地址',
  Contact_number: '联络电话',
  School: '曾就读之学校',
  Class: '曾就读班级',
  Volunteer: '学制志愿',
  Full: '班额已满时，由本园编排学位',
  twin_Chinese_name: '双胞胎/多胞胎中文姓名',
  twin_English_name: '双胞胎/多胞胎英文姓名',
  twin_Gender: '双胞胎/多胞胎姓别',
  twin_date_of_birth: '双胞胎/多胞胎出生日期',
  twin_Place_of_birth: '双胞胎/多胞胎出生地点',
  Father_information: '父亲资料',
  Mother_information: '母亲资料',
  Orther_information: '监护人资料',
  Mobile_phone: '手提电话',
  Occupation: '职业',
  Graduates: '是否本园毕业生',
  Year_graduation: '毕业年份',
  Staff: '是否本园之全职员工',
  Cname: '中文姓名',
  attend_school: '正于本园就读的兄姊',
  Graduated: '于本园毕业的兄姊',
  Apply_for_reading: '兄弟姊妹同时报读',
  Study_kindergarten: '该申请者之兄姊正于本园就读',
  Relationship: '与申请者关系',
  Year_study: '就读年份',
  Graduation: '该申请者之兄姊是本园毕业生',
  team: '班级',
  In_operation: '处理中......',
  Later: '付款页面将会呈现，请稍候....',
  Application_No: '申请编号',
  Name_applicant: '申请者姓名',
  Registration_fee: '报名费用',
  payment_method: '付款方式',
  Count_paper: '上传报名费入数纸',
  Input_data: '入数资料',
  Bank_name: '银行名称',
  Account_name: '户口名称',
  Account_number: '户口号码',
  Upload_attachments: '上传附件',
  read: '该申请者之兄姊是同时报读',
  reading: '新增同时报读兄姊',
  least_one: '最少填写一位监护人资料',
  upload_err: '上传失败',
  save_progress: '储存进度',
  save_suc: '暂存成功',
  save_err: '暂存失败',
  status: '付款状态',
  cannot_yet: '尚未付款',
  paid_suc: '已付款',
  is_all_payment: '已完成付款，返回新生报名首页',
  leave: '前往付款',
  look_over: '查看资料',
  personage: '查看收集个人资料声明',
  ont_OK: '本人已确定资料正确无误',
  accept: '本人已阅读及同意「收集个人资料声明」',
  WHOLE: '全日班',
  AM: '上午班',
  PM: '下午班',
  session_1: '志愿 1:',
  session_2: '志愿 2:',
  session_3: '志愿 3:',
  session_4: '志愿 4:',
  session_5: '志愿 5:',
  session_6: '志愿 6:',
  session_7: '志愿 7:',
  session_8: '志愿 8:',
  session_9: '志愿 9:',
  session_10: '志愿 10:',
  volunteer: '志愿',
  payment: '付款',
  submit: '提交',
  objectiveDate:
    ' 申请人必须在注明(*)之栏目中提供所需的个人资料。如未能提供，校方未必能处理有关之入学申请。',
  provideDate:
    '根据《个人资料（私隐）条例》，本申请表内有关个人资料将仅供校方作处理入学申请之用。在未得到申请人的同意之前，校方不会向第三者披露或转移所收集的个人资料。',
  termDate:
    '如入学申请不被接纳，校方将销毁一切有关个人的资料；成功申请者的个人资料将会存入学生档案中，并由校方保管。',
  TransferDate:
    '根据《个人资料（私隐）条例》，申请人有权查阅及更改填报时所提供的资料，并可索取此等资料的副本。申请人如须查阅或更改其个人资料，请致函告知本园主任。',
  drag: '*请拖动方块以作排序',
  Upload_files: '上传档案',
  room: '室',
  floor: '楼',
  seat: '座',
  house: '屋/村名称',
  street: '街道名称及号数',
  area: '分区',
  meanwhiles: '双胞胎/多胞胎同时报名',
  additional: '新增',
  delete: '删除',
  Father_data: '父亲资料',
  Mother_data: '母亲资料',
  Add_other: '新增其他监护人',
  others: '其他监护人资料',
  or: '该申请者之父母或兄姊是本园毕业生',
  graduate_fa: '该申请者之父亲是本园之毕业生',
  graduate_ma: '该申请者之母亲是本园之毕业生',
  graduate_or: '该申请者之监护人是本园之毕业生',
  employee_fa: '该申请者之父亲是本园之全职员工',
  employee_ma: '该申请者之母亲是本园之全职员工',
  employee_or: '该申请者之监护人是本园之全职员工',
  relation: '与申请者关系',
  verify: '确认及付款',
  return_home: '返回首页',
  paymented: '查阅/更改资料',
  payment_cancel: '取消付款',
  payment_again: '再次付款',
  apply_success: '确认已收到你的申请',
  archive_no: '你的申请编号为：',
  Confirm_notification: '「确认通知」已发送至你与入学申请表上填写的电邮地址。',
  // Confirm_notification: '「确认通知」已发送至所登记之电邮地址。',
  checked_details: '你可使用申请者资料登入首页的「查阅／更改资料」功能。',
  tip: '注意：如你未能收取确认电邮，可以点击本网站右上方之「常见问题」查看处理方式。',
  paid: '付款成功！',
  elder_brother: '兄',
  elder_sister: '姐',
  require: '必须填写',
  hk_id_card: '请输入正确的出生证明书登记编号',
  room_required: '室不能为空',
  floor_required: '楼不能为空',
  block_required: '座不能为空',
  village_required: '大厦/屋苑名称不能为空',
  area_required: '分区不能为空',
  street_required: '街道名称及号数不能为空',
  district_required: '地区不能为空',
  class_required: '请拖动方块以作学制志愿排序',
  email_error: '输入的电邮地址不一致，请重新输入！',
  email_required: '请重新输入电邮地址',
  isMutiple: '请选择是否有双胞胎/多胞胎同时报名',
  Central_Western: '中西区',
  Eastern: '东区',
  Island: '离岛区',
  Kowloon_City: '九龙城区',
  Kwai_Tsing: '葵青区',
  Kwun_Tong: '观塘区',
  North: '北区',
  Sai_Kung: '西贡区',
  Sha_Tin: '沙田区',
  Sham_Shui_Po: '深水埗区',
  Southern: '南区',
  Tai_Po: '大埔区',
  Tsuen_Wan: '荃湾区',
  Tuen_Mun: '屯门区',
  Wan_Chai: '湾仔区',
  Wong_Tai_Sin: '黄大仙区',
  Yau_Tsim_Mong: '油尖旺区',
  Yuen_Long: '元朗区',
  first_verify_email: '系统需要验证你的登记电邮地址',
  verify_code_send: '验证码已传送到：',
  input_verify_code: '输入验证码',
  verify_email: '验证',
  cancel: '取消',
  no_email_received: '收不到电邮？',
  send_again: '再次发送',
  obsolete_failure: '验证码过时失效',
  verify_code_error: '验证码错误',
  email_tip:
    '经电邮验证後，在未完成提交申请的情况下，可于首页选择「网上入学申请表」，并输入之前的基本登记资料，便能于目前所选属校及级别中查阅已储存之报名资料，并继续报名程序。',
  'parent.is_school_graduate': '是否本园之毕业生',
  'parent.is_school_employee': '是否本园之全职员工',
  voluteer_agreement: '我同意留空以上选项，以表示不考虑该学制。',
  err: '必须剔选',
  no_result: '没有纪录',
  select_address: '请选择地址。',
  enterMore: '请输入更多关键字，以寻找更准确结果。',
  IsToRead: '正在报读',
  print: '列印',
  Nooptions: '没有资料',
  select_view_time: '选择面见时间',
  date_time: '面见日期及时间',
  change_lang: '更改面试时间',
  selected: '已选择',
  confirm_time: '确认',
  file_larger: '档案不能大于',
  type_error: '档案格式错误',
  login_err: '登入验证失败',
  submit_err: '资料提交失败',
  submit_err_211_212: '不能重复提交报名表',
  submit_err_220: '报名提交时间已结束',
  submit_err_300: '面试时段名额已满',
  file_size: '档案不能大于{{size}}MB',
  file_accept: '允许档案格式: {{accept}}',
  sort_1: '第 1 志愿',
  sort_2: '第 2 志愿',
  sort_3: '第 3 志愿',
  sort_4: '第 4 志愿',
  sort_5: '第 5 志愿',
  sort_6: '第 6 志愿',
  sort_7: '第 7 志愿',
  sort_8: '第 8 志愿',
  sort_9: '第 9 志愿',
  sort_10: '第 10 志愿',
  change_suc: '修改成功',
  change_fail: '修改失败',
  no_record: '没有可选择的时间',
  stored: '已储存',
  storage: '储存',
  // Gabriel End Here 29-May-2023
  accept_left: '本人已阅读',
  accept_right: '收集个人资料声明',
  delete_tip: '是否需要重新上传档案',
  yes: '是',
  no: '否',
  district: '地区 :',
  submit_err_213: '出生证明书/护照/其他号码重覆',
  too_long: '图片宽度或高度大于 4000px',
};
export default config;
