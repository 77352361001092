import { createSlice } from '@reduxjs/toolkit';
import { getInterviewTime } from '../../api/interview';

const initialState = {
  interviewInfo: {},
  interviewTimeList: [],
  phaseInfo: {},
  // 選擇面試時間成功時的time_id
  timeId: '',
};

const slice = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setInterviewInfo(state, { payload }) {
      state.interviewInfo = payload;
    },
    setPhaseInfo(state, { payload }) {
      state.phaseInfo = { ...payload };
    },
    setTimeList(state, { payload }) {
      state.interviewTimeList = payload;
      state.isLoading = false;
    },
    setSuccessTimeId(state, { payload }) {
      state.timeId = payload;
    },
  },
});

export default slice.reducer;

export const { setInterviewInfo, setPhaseInfo, setSuccessTimeId } =
  slice.actions;

export function fetchInterviewTimes(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getInterviewTime(params);
      dispatch(slice.actions.setTimeList(response.rows));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
