/* eslint-disable camelcase */
import { client } from 'lib/evi-api-gateway';

const url = `${process.env.REACT_APP_API_URL}/auth`;

export async function login({
  master_app_id,
  cert_type,
  cert_id,
  birthday,
  email,
  school_id,
  verify_code,
  recaptcha,
  token,
}) {
  return client.post({
    url: `${url}/login`,
    form: {
      master_app_id,
      cert_type,
      cert_id,
      birthday,
      email,
      school_id,
      verify_code,
      recaptcha,
      token,
    },
  });
}
// 重发验证码
export async function resend(params) {
  return client.post({
    url: `${url}/resend`,
    form: {
      ...params,
    },
  });
}
// 验证邮箱
export async function verifyEmail({
  master_app_id,
  cert_type,
  cert_id,
  birthday,
  email,
  school_id,
  verify_code,
}) {
  return client.post({
    url: `${url}/verify_email`,
    form: {
      master_app_id,
      cert_type,
      cert_id,
      birthday,
      email,
      school_id,
      verify_code,
    },
  });
}

export default {
  login,
  resend,
  verifyEmail,
};
