const config = {
    know: 'I acknowledge that I have read and understood the above online admission procedures.',
    next_step: 'Next',
    go_back: 'Back',
    tip_next:
    'Please check the "I acknowledge that I have read and understood the above online admission procedures." box',

    Conditions_overleaf: 'Online Application Procedure: ',
    program_1: '1. Select the kindergarten/nursery you want to apply.',
    program_2:
    '2. Please fill out the application form and upload the child\'s "Birth Certificate" and "Photo ID within the last 3 months" to the relevant location.',
    program_3:
    '3. Application fee is HKD$40.00.(Payment method: (1)By Visa/MasterCard or (2)Via Bank ATM/Bank Transfer and upload the bank slip to the Application System).',
    program_3_1:
    'In case of Bank ATM/Bank Transfer, please bank-in to the designated account of our affiliated schools.',
    program_3_2:
    'In case of Bank ATM/Bank Transfer, please keep the receipt and bring to school for verification on the day of the interview.',
    program_3_3:
    '＊ No application fee is needed for applying at Po Leung Kuk Choi Koon Shum(Opulent House)Nursery',
    program_4:
    '4. After successfully submitting the application form, the system will send a "Application Confirmation" email. For inquiries, please call the kindergarten/nursery school you are enrolled in during office hours.',
    program_5: '5. Please bring the following documents on the day of the interview:',
    program_5_1: 'Original copy of the child’s Birth Certificate',
    program_5_2:
    'The original copy of the application fee bank slip (for applicants who have applied in the online system and uploaded a photo of the application fee bank slip)',
    remark: 'Remarks:',
    remark_1:
    '1. The applicants must be Hong Kong residents, have the right of abode in Hong Kong, the right to land or a valid permit to stay in Hong Kong without any condition of stay (except for the period of stay).',
    remark_2:
    '2. Once the application fee is paid, it is non-refundable. Please confirm the bank account number of the kindergarten/nursery school you want to apply for.',
    remark_3:
    '3. Please add the correspondence email address (noreply@smtp.plkkgs.edu.hk) into the contact list/white list to avoid being identified as junk mail.',
    // Gabriel End Here 29-May-2023
};
export default config;
