const config = {
  welcome_online_apply_system: '歡迎使用網上入學申請系統',
  online_appliy_form: '網上入學申請表',
  change_print_data: '查閲/更改資料',
  common_problem: '常見問題',
  lang: '繁中',
  set_lang: '語言設定',
  zh: '繁體中文',
  cn: '簡體中文',
  english: '英文',
  support_phone: '支援熱線：',
  notice: '訊息中心',
  notice_total: '你有{{totalUnRead}}個新訊息',
  msg_sccuss: '刪除成功',
  no_admission_form: '沒有報名表',
  preview: '文件瀏覽',
  accept_result: '我們已收到您的回覆',
  // Gabriel End Here 29-May-2023,
  veo_copyRight: '維多利亞教育機構。 版權所有。',
};
export default config;
