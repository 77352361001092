import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  schoolCode: null,
};

const slice = createSlice({
  name: 'ns-global',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setSchoolCode(state, { payload }) {
      state.isLoading = false;
      state.schoolCode = payload;
    },
  },
});

export default slice.reducer;

export const { setSchoolCode } = slice.actions;
