import { configureStore } from '@reduxjs/toolkit';
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { rootPersistConfig, rootReducer } from './rootReducer';

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: persistReducer(rootPersistConfig, rootReducer),
});

const { dispatch } = store;
const useSelector = useReduxSelector;
const useDispatch = () => useReduxDispatch();
const persistor = persistStore(store);

export { store, persistor, dispatch, useSelector, useDispatch };
