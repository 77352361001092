const config = {
  change_print_data: '查閲或更改資料／列印表格',
  next_step: '下一步',
  go_back: '返回',
  apply_child_name: '申請幼兒姓名',
  Birthdate: '出生日期',
  Certificate: '幼兒的香港出生證明書號碼',
  orther: '其他（非本地出生證明書號碼或護照號碼）',
  registration_number: '如：A123456(7)，請輸入：A1234567',
  correct: '格式錯誤',
  E_mail: '電郵地址',
  phone_or_email: '聯絡電話/電郵地址',
  required: '必須填寫',
  err_email: '請輸入有效電子郵件地址',
  login_err: '登入失敗',
  login_err210: '沒有資料',
  login_suc: '登入成功',
  apply_tip: '登入資料不正確',
  school_name: '學校名稱',
  apply_num: '申請編號',
  apply_year: '申請年級',
  receipt: '收據',
  interview_date: '面見日期',
  form_task: '後補資料          ',
  apply_status: '付款狀態',
  operation: '操作',
  open: '打開',
  look: '查看',
  receive_apply: '已收申請',
  change: '更改',
  change_end: '更改期已屆滿',
  logout: '登出',
  other_school: '報讀其他學校',
  enter_link: '進入問卷頁',
  apply_title:
    '本幼稚園收到申請人{{name}}入讀{{grade}}的申請，並已獲安排面試時間。詳情如下：',
  address:
    '面試當日請由一位家長/監護人陪同幼兒於十分鐘前到達，到「保良局莊啟程大廈地下梁王培芳堂接待處」登記，並出示以下文件。',
  download_attachment: '下載附件',
  print: '列印',
  date: '日期',
  time: '時間',
  please_pay_attention: '請注意',
  attention_1:
    '面試時間不能更改，如因面試當天缺席而欲申請後補面試，需電郵致學校作申請。',
  attention_2:
    '如因身體不適而未能出席面試，需向學方提交醫生證明書，學方稍後會通知有關後補面試的安排。',
  attention_3:
    '遲到者要待校方完成當日所有準時到達的家長和小朋友的面試，才會被安排當日的後補面試時間。',
  attention_4: '如尚未支付報名費，可於面試當日以現金支付。',
  View: '查看',
  immediatePayment: '立即付款',
  Info: '資訊',
  DocumenID: '護照號碼或其他證件號碼',
  Norecord: '沒有資料',
  declaration: '本人已閱讀及接受有關',
  Statement: '收集個人資料聲明',
  select_time: '按此選擇面試時間',
  BankSlip: '報名費入數紙',
  // Gabriel End Here 29-May-2023
  recaptcha_error: '驗證錯誤',
};
export default config;
