const config = {
  select_school_year: '报读年级',
  enter_basic_info: '基本资料',
  please_select_kindergarten: '报读幼稚园',
  select_grade: '请选择年级',
  please_enter_register_form: '报名表格',
  confirm_and_payment: '确认及付款',
  complete: '完成',
  apply_school_year: '申请报读学年',
  freshman_apply: '新生入学申请(2022-2023)',
  select_apply_grade: '选择申请年级',
  select_kindergarten: '选择报读的幼稚园',
  enter_register_form: '填写入学报名表格',
  next_step: '下一步',
  last_step: '上一步',
  go_back: '返回',
  previous_page: '上一页',
  confirm: '确定',
  provide: '(提供 {{time}})',
  provides: '提供',
  Not_open: '(未开放)',
  none: '没有纪录',
  tip_select_grade: '请选择欲报读的幼稚园',
  leave_page: '是否确定离开此页面？',
  first_verify_email: '首次使用需验证你的电邮地址',
  code_sened: '验证码已发送到',
  resen_code: '重新发送验证码',
  verify_suc: '验证成功',
  verify_err: '验证失败，请输入正确的验证码',
  // Gabriel End Here 29-May-2023
  not_edit: '已截止更改',
};
export default config;
