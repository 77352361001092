import { v5 as uuidv5 } from 'uuid';

export default function generateUUID() {
  const uuid = `web-${uuidv5(
    (process.env['REACT_APP_NAME'] || 'EVI_APPLICATION_NAME') +
      (
        Math.floor(Math.random() * Math.floor(10000000)).toString() +
        (Date.now() % 1000) / 1000
      ).toString(),
    uuidv5(process.env['DOMAIN'] || 'EVI_APPLICATION_DOMAIN', uuidv5.DNS)
  )}`;
  return uuid;
}
