const config = {
    know: '本人確認已閱讀及明白以上有關網上辦理新生入學申請之程序',
    next_step: '下一步',
    go_back: '返回',
    tip_next: '請剔選「本人已閱讀及明白以上有關網上新生入學申請程序」',
    Conditions_overleaf: '網上報名程序：',
    program_1: '1. 請選擇欲報讀之屬下幼稚園/幼兒園。',
    program_2:
    '2. 請填妥報名表，並將兒童「出生證明文件」及「最近三個月內之證件相片」上載到相關的位置。',
    program_3:
    '3. 報名費為＊港幣$40元正(繳費方式︰(1)直接以Visa、Master付款 或 (2)親自到銀行/自動櫃員機入數，並將入數紙上載至報名系統)',
    program_3_1: '如選用銀行轉賬，請存入屬下幼稚園/幼兒園之指定銀行戶口',
    program_3_2: '如選用銀行轉賬，必須保留入數紙存根，並於面見日交回校方以作核實',
    program_3_3: '＊保良局蔡冠深（澤富樓）幼兒園無須收取報名費',
    program_4:
    '4. 成功遞交入學申請表後，系統會發出一封「確認入學申請電郵通知書」電郵，請不要回覆此電郵。如需查詢，請於辦公時間致電所報讀之屬下幼稚園/幼兒園。',
    program_5: ' 5. 請於面試當日帶同以下資料：',
    program_5_1: '兒童出生證明書正本',
    program_5_2:
    '報名費入數紙存根之正本(適用於在網上系統作報名，並已上傳報名費入數紙照片之申請者)',
    remark: '備註：',
    remark_1:
    '1. 申請學童必須是香港居民、擁有香港居留權、入境權或不附帶任何逗留條件(逗留期限除外)的有效香港居留許可。',
    remark_2:
    '2. 報名費一經繳交，恕不退還，請先確認欲申請報讀之屬下幼稚園/幼兒園之銀行戶口號碼。',
    remark_3:
    '3. 請先將此電郵地址(noreply@smtp.plkkgs.edu.hk)加入至聯絡人名單，以免系統所發出的郵件被置於垃圾郵件中。',
    // Gabriel End Here 29-May-2023
};
export default config;
