const config = {
  enter_register_form: '填寫入學報名表格',
  Confirm_data: '確認資料',
  Choose_payment_method: '選擇付款方式',
  Choose_child: '選擇幼兒',
  admission_date: '入數日期',
  next_step: '下一步',
  last_step: '上一步',
  go_back: '返回',
  previous_page: '上一頁',
  confirm: '確定',
  first_party: '甲部 : 申請之幼兒資料',
  party_B: '乙部 : 父母/監護人資料',
  party_C: '丙部 : 其他資料',
  apply_student_info: '申請學生資料',
  graduate: '本園畢業的兄姊：',
  Yes: '是',
  No: '否',
  relationship: '與申請者關係：',
  select: '請選擇',
  other: '其他',
  Chinese_name: '中文姓名',
  Year_of_graduation: '畢業年份：',
  newly_added: '新增正在本園就讀之兄姊',
  meanwhile: '兄姊同時報讀：',
  Number: '香港出生證明書編號',
  Apply_reading: '申請年級',
  new: '新增本園畢業之兄姊',
  mandatory: '*為必填項目',
  please_select: '--請選擇--',
  pleas_input: '請輸入....',
  district_select: '地區',
  multiple: '是否有雙胞胎/多胞胎同時報名',
  English_name: '英文姓名',
  Gender: '性別',
  Man: '男',
  Woman: '女',
  date_of_birth: '出生日期',
  Place_of_birth: '出生地點',
  Type_birth: '出生證明書類型',
  Certificate: '香港出生證明書',
  serial: '出生證明書登記編號',
  copy: '出生證明書副本：',
  Effective: '有效護照及香港簽證(非香港出生使用)：',
  least: '*最少填寫一位監護人資料',
  Add_another_Guardian: '新增其他監護人',
  Applied_school: '申請學校：',
  Academic_year: '申請學年：',
  Application_grade: '申請年級：',
  Statement: '收集個人資料聲明',
  objective: '收集資料的目的：',
  provide: '需提供的資料:',
  term: '資料的保存期限：',
  Transfer: '資料的轉交：',
  Rights: '申請者權利：',
  declaration: '本人已閱讀及接受以上有關收集個人資料聲明',
  latest_photo: '學生近照',
  twin: '雙胞胎/多胞胎資料：',
  address: '地址',
  Area: '地區',
  nationality: '國籍',
  Commonly_used: '在家常用語言',
  Email_address: '電郵地址',
  Contact_number: '聯絡電話',
  School: '曾就讀之學校',
  Class: '曾就讀班級',
  Volunteer: '學制志願',
  Full: '班額已滿時，由本園編排學位',
  twin_Chinese_name: '雙胞胎/多胞胎中文姓名',
  twin_English_name: '雙胞胎/多胞胎英文姓名',
  twin_Gender: '雙胞胎/多胞胎姓別',
  twin_date_of_birth: '雙胞胎/多胞胎出生日期',
  twin_Place_of_birth: '雙胞胎/多胞胎出生地點',
  Father_information: '父親資料',
  Mother_information: '母親資料',
  Orther_information: '監護人資料',
  Mobile_phone: '手提電話',
  Occupation: '職業',
  Graduates: '是否本園畢業生',
  Year_graduation: '畢業年份',
  Staff: '是否本園之全職員工',
  Cname: '中文姓名',
  attend_school: '正於本園就讀的兄姊',
  Graduated: '於本園畢業的兄姊',
  Apply_for_reading: '兄弟姊妹同時報讀',
  Study_kindergarten: '該申請者之兄姊正於本園就讀',
  Relationship: '與申請者關係',
  Year_study: '就讀年份',
  Graduation: '該申請者之兄姊是本園畢業生',
  team: '班級',
  In_operation: '處理中......',
  Later: '付款頁面將會呈現，請稍候....',
  Application_No: '申請編號',
  Name_applicant: '申請者姓名',
  Registration_fee: '報名費用',
  payment_method: '付款方式',
  Count_paper: '上傳報名費入數紙',
  Input_data: '入數資料',
  Bank_name: '銀行名稱',
  Account_name: '戶口名稱',
  Account_number: '戶口號碼',
  Upload_attachments: '上傳附件',
  read: '該申請者之兄姊是同時報讀',
  reading: '新增同時報讀兄姊',
  least_one: '最少填寫一位監護人資料',
  upload_err: '上傳失敗',
  save_progress: '儲存進度',
  save_suc: '暫存成功',
  save_err: '暫存失敗',
  status: '付款狀態',
  cannot_yet: '尚未付款',
  paid_suc: '已付款',
  is_all_payment: '已完成付款，返回新生報名首頁',
  leave: '前往付款',
  look_over: '查看資料',
  personage: '查看收集個人資料聲明',
  ont_OK: '本人已確定資料正確無誤',
  accept: '本人已閱讀及同意「收集個人資料聲明」',
  WHOLE: '全日班',
  AM: '上午班',
  PM: '下午班',
  session_1: '志願 1:',
  session_2: '志願 2:',
  session_3: '志願 3:',
  session_4: '志願 4:',
  session_5: '志願 5:',
  session_6: '志願 6:',
  session_7: '志願 7:',
  session_8: '志願 8:',
  session_9: '志願 9:',
  session_10: '志願 10:',
  volunteer: '志願',
  payment: '付款',
  submit: '提交',
  objectiveDate:
    ' 申請人必須在註明(*)之欄目中提供所需的個人資料。如未能提供，校方未必能處理有關之入學申請。',
  provideDate:
    '根據《個人資料（私隱）條例》，本申請表內有關個人資料將僅供校方作處理入學申請之用。在未得到申請人的同意之前，校方不會向第三者披露或轉移所收集的個人資料。',
  termDate:
    '如入學申請不被接納，校方將銷毀一切有關個人的資料；成功申請者的個人資料將會存入學生檔案中，並由校方保管。',
  TransferDate:
    '根據《個人資料（私隱）條例》，申請人有權查閱及更改填報時所提供的資料，並可索取此等資料的副本。申請人如須查閱或更改其個人資料，請致函告知本園主任。',
  drag: '*請拖動方塊以作排序',
  Upload_files: '上傳檔案',
  room: '室',
  floor: '樓',
  seat: '座',
  house: '屋/村名稱',
  street: '街道名稱及號數',
  area: '分區',
  meanwhiles: '雙胞胎/多胞胎同時報名',
  additional: '新增',
  delete: '刪除',
  Father_data: '父親資料',
  Mother_data: '母親資料',
  Add_other: '新增其他監護人',
  others: '其他監護人資料',
  or: '該申請者之父母或兄姊是本園畢業生',
  graduate_fa: '該申請者之父親是本園之畢業生',
  graduate_ma: '該申請者之母親是本園之畢業生',
  graduate_or: '該申請者之監護人是本園之畢業生',
  employee_fa: '該申請者之父親是本園之全職員工',
  employee_ma: '該申請者之母親是本園之全職員工',
  employee_or: '該申請者之監護人是本園之全職員工',
  relation: '與申請者關係',
  verify: '確認及付款',
  return_home: '返回首頁',
  paymented: '查閱/更改資料',
  payment_cancel: '取消付款',
  payment_again: '再次付款',
  apply_success: '確認已收到你的申請',
  archive_no: '你的申請編號為：',
  Confirm_notification: '「確認通知」已發送至你於入學申請表上填寫的電郵地址。',
  // Confirm_notification: '「確認通知」已發送至所登記之電郵地址。',
  checked_details: '你可使用申請者資料登入首頁的「查閱／更改資料」功能。',
  tip: '注意：如你未能收取確認電郵，可以點擊本網站右上方之「常見問題」查看處理方式。',
  paid: '付款成功！',
  elder_brother: '兄',
  elder_sister: '姐',
  require: '必須填寫',
  hk_id_card: '請輸入正確的出生證明書登記編號',
  room_required: '室不能為空',
  floor_required: '樓不能為空',
  block_required: '座不能為空',
  village_required: '大廈/屋苑名稱不能為空',
  area_required: '分區不能為空',
  street_required: '街道名稱及號數不能為空',
  district_required: '地區不能為空',
  class_required: '請拖動方塊以作學制志願排序',
  email_error: '輸入的電郵地址不一致，請重新輸入！',
  email_required: '請重新輸入電郵地址',
  isMutiple: '請選擇是否有雙胞胎/多胞胎同時報名',
  Central_Western: '中西區',
  Eastern: '東區',
  Island: '離島區',
  Kowloon_City: '九龍城區',
  Kwai_Tsing: '葵青區',
  Kwun_Tong: '觀塘區',
  North: '北區',
  Sai_Kung: '西貢區',
  Sha_Tin: '沙田區',
  Sham_Shui_Po: '深水埗區',
  Southern: '南區',
  Tai_Po: '大埔區',
  Tsuen_Wan: '荃灣區',
  Tuen_Mun: '屯門區',
  Wan_Chai: '灣仔區',
  Wong_Tai_Sin: '黃大仙區',
  Yau_Tsim_Mong: '油尖旺區',
  Yuen_Long: '元朗區',
  first_verify_email: '系統需要驗證你的登記電郵地址',
  verify_code_send: '驗證碼已傳送到：',
  input_verify_code: '輸入驗證碼',
  verify_email: '驗證',
  cancel: '取消',
  no_email_received: '收不到電郵？',
  send_again: '再次發送',
  obsolete_failure: '驗證碼過時失效',
  verify_code_error: '驗證碼錯誤',
  email_tip:
    '經電郵驗證後，在未完成提交申請的情況下，可於首頁選擇「網上入學申請表」，並輸入之前的基本登記資料，便能於目前所選屬校及級別中查閱已儲存之報名資料，並繼續報名程序。',
  'parent.is_school_graduate': '是否本園之畢業生',
  'parent.is_school_employee': '是否本園之全職員工',
  voluteer_agreement: '我同意留空以上選項，以表示不考慮該學制。',
  err: '必須剔選',
  no_result: '沒有紀錄',
  select_address: '請選擇地址。',
  enterMore: '請輸入更多關鍵字，以尋找更準確結果。',
  IsToRead: '正在報讀',
  print: '列印',
  Nooptions: '沒有資料',
  select_view_time: '選擇面見時間',
  date_time: '面見日期及時間',
  change_lang: '更改面試時間',
  selected: '已選擇',
  confirm_time: '確認',
  file_larger: '檔案不能大於',
  type_error: '檔案格式錯誤',
  login_err: '登入驗證失敗',
  submit_err: '資料提交失敗',
  submit_err_211_212: '不能重覆提交報名表',
  submit_err_220: '報名提交時間已結束',
  submit_err_300: '面試時段名額已滿',
  file_size: '檔案不能大於{{size}}MB',
  file_accept: '允許檔案格式: {{accept}}',
  sort_1: '第 1 志願',
  sort_2: '第 2 志願',
  sort_3: '第 3 志願',
  sort_4: '第 4 志願',
  sort_5: '第 5 志願',
  sort_6: '第 6 志願',
  sort_7: '第 7 志願',
  sort_8: '第 8 志願',
  sort_9: '第 9 志願',
  sort_10: '第 10 志願',
  change_suc: '修改成功',
  change_fail: '修改失敗',
  no_record: '沒有可選擇的時間',
  stored: '已儲存',
  storage: '儲存',
  // Gabriel End Here 29-May-2023
  accept_left: '本人已閱讀',
  accept_right: '收集個人資料聲明',
  delete_tip: '是否需要重新上傳檔案',
  yes: '是',
  no: '否',
  district: '地區 :',
  submit_err_213: '出生證明書/護照/其他號碼重覆',
  too_long: '圖片闊度或高度大於 4000px',
};
export default config;
