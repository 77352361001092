import NProgress from 'nprogress';
import React, { useEffect, useMemo } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
  '& .loading': {
    position: 'relative',
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    border: '3px solid transparent',
    borderTopColor: '#369FFF',
    animation: 'rotate 2s linear infinite',
  },
  '& .loading::before': {
    // eslint-disable-next-line quotes
    content: "''",
    position: 'absolute',
    top: '5px',
    right: '5px',
    bottom: '5px',
    left: '5px',
    borderRadius: '50%',
    border: '3px solid transparent',
    borderTopColor: '#FF6E6E',
    animation: 'rotate 3s linear infinite',
  },
  '& .loading::after': {
    // eslint-disable-next-line quotes
    content: "''",
    position: 'absolute',
    top: '15px',
    right: '15px',
    bottom: '15px',
    left: '15px',
    borderRadius: '50%',
    border: '3px solid transparent',
    borderTopColor: '#FF8D4E',
    animation: 'rotate 1.5s linear infinite',
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

// ----------------------------------------------------------------------

export function ProgressBarStyle() {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        '#nprogress': {
          pointerEvents: 'none',
          '& .bar': {
            top: 0,
            left: 0,
            height: 2,
            width: '100%',
            position: 'fixed',
            zIndex: theme.zIndex.snackbar,
            backgroundColor: theme.palette.primary.main,
            boxShadow: `0 0 2px ${theme.palette.primary.main}`,
          },
          '& .peg': {
            right: 0,
            opacity: 1,
            width: 100,
            height: '100%',
            display: 'block',
            position: 'absolute',
            transform: 'rotate(3deg) translate(0px, -4px)',
            boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`,
          },
        },
      }}
    />
  );
}

function ProgressBar() {
  NProgress.configure({
    showSpinner: false,
  });

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return null;
}

export default function LoadingScreen({ ...other }) {
  return (
    <>
      <ProgressBar />
      <RootStyle {...other}>
        <div className="loading" />
      </RootStyle>
    </>
  );
}
