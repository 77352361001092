import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import authReducer from './slice/auth';
import stepReducer from './slice/step';
import mainReducer from './slice/main';
import userReducer from './slice/user';
import tokenReducer from './slice/token';
import formReducer from './slice/form';
import globalReducer from './slice/global';
import formTaskReducer from './slice/formTask';
import interviewReducer from './slice/interview';

// ----------------------------------------------------------------------

function createRootPersistConfig() {
  if (process.env.REACT_APP_LOGIN_VALIDATE === 'true') {
    return {
      key: 'root',
      storage,
      keyPrefix: 'redux-',
      whitelist: [],
    };
  }
  return {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    blacklist: [],
  };
}

const rootPersistConfig = createRootPersistConfig();

// const rootPersistConfig = {
//   key: 'root',
//   storage,
//   keyPrefix: 'redux-',
//   blacklist: [],
// };

// const stepPersistConfig = {
//   key: 'step',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['payPage', 'nsUserId', 'isHasPay'],
// };

const rootReducer = combineReducers({
  auth: authReducer,
  step: stepReducer,
  main: mainReducer,
  user: userReducer,
  form: formReducer,
  formTask: formTaskReducer,
  validate: tokenReducer,
  global: globalReducer,
  interview: interviewReducer,
});

export { rootPersistConfig, rootReducer };
