const config = {
  select_school_year: 'Grade Applied For',
  enter_basic_info: 'Basic Information',
  please_select_kindergarten: 'Kindergarten Applied For',
  select_grade: 'Select grade',
  please_enter_register_form: 'Application Form',
  confirm_and_payment: 'Confirm & Pay',
  complete: 'Done',
  apply_school_year: 'School Year Applied For',
  freshman_apply: 'Application for New Student Admission (2022-2023)',
  select_apply_grade: 'Select Grade Applied For',
  select_kindergarten: 'Select Kindergarten Applied For',
  enter_register_form: 'Fill out the application form',
  next_step: 'Next',
  last_step: 'Back',
  go_back: 'Back',
  previous_page: 'Back',
  confirm: 'Confirm',
  provide: '(Provide {{time}})',
  provides: 'Provide',
  Not_open: '(Not in use)',
  none: 'No records',
  tip_select_grade: 'Please select the kindergarten you want to apply for.',
  leave_page: 'Do you confirm to leave this page ?',
  first_verify_email:
    'Your email address needs to be verified for first-time use.',
  code_sened: 'The verification code has been sent to ',
  resen_code: 'Resend verification code',
  verify_suc: 'Verification Success',
  verify_err:
    'Verification Failure. Please enter the correct verification code.',
  // Gabriel End Here 29-May-2023
  not_edit: 'Not available to edit',
};
export default config;
