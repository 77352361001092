/* eslint-disable no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
import { createSlice } from '@reduxjs/toolkit';

// api
import { getForm, submitForm, getSubForm, saveTemeForm } from 'api/form';

const initialState = {
  sections: [],
  first_party: null,
  PartC: null,
  PartB: null,
  schoolYear: null,
  submitResult: [],
  isLoading: false,
  error: { result: null, msg: null },
  openViewer: false,
  formTask: { name: '' },
  autoSaveFormResult: true,
  printHtml: null,
  last_tmp_save_time: null,
};

const slice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    getSections(state, action) {
      const { form_item_list: formItem, print_html: printHtml } =
        action.payload;

      // console.log(formItem, printHtml);
      // if action existence set printHtml content
      if (action) {
        state.printHtml = printHtml;
      }
      state.sections = formItem;
    },
    getOpenViewer(state, action) {
      state.openViewer = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // from表单搜集的甲部资料
    getfirstParty(state, action) {
      state.isLoading = false;
      state.first_party = action.payload;
    },
    // from表单搜集的已部资料
    getfirstPartB(state, action) {
      state.isLoading = false;
      state.PartB = action.payload;
    },
    // from表单搜集的丙部资料
    getfirstPartC(state, action) {
      state.isLoading = false;
      state.PartC = action.payload;
    },
    // 收集选择报读的年级
    getSchoolYear(state, action) {
      state.isLoading = false;
      state.schoolYear = action.payload;
    },
    // submit 提交表单数据
    fetchSubmit(state, action) {
      state.isLoading = false;
      state.submitResult = action.payload;
    },

    // form task current data
    setFormTask(state, action) {
      state.formTask = action.payload;
    },

    // auto Save form data result
    setAutoSaveForm(state, action) {
      state.autoSaveFormResult = action.payload;
    },

    clearCacheForm(state) {
      state.first_party = null;
      state.PartB = null;
      state.PartC = [];
    },
    resetInitialState() {
      return initialState;
    },
    resetFormTaskData(state) {
      state.sections = [];
      state.first_party = null;
    },
    setlastTmpSaveTime(state, action) {
      state.last_tmp_save_time = action.payload;
    },
    // 新生报名返回的填写基本信息时，保留birth_start等
    resetState(state) {
      return { ...initialState, schoolYear: state.schoolYear };
    },
  },
});
// Actions
export const {
  getSchoolYear,
  resetInitialState,
  clearCacheForm,
  getOpenViewer,
  resetFormTaskData,
  resetState,
} = slice.actions;

export default slice.reducer;

export function getFormAction({ onError, callback, ...params }) {
  return async (dispatch) => {
    try {
      // const response = await getFormList(params);
      const response = await getForm(params);
      // console.log(response);
      // console.log(formData);
      // console.log(formData.print_html);

      if (Array.isArray(response.form_item_list)) {
        dispatch(slice.actions.getSections(response));
        dispatch(
          slice.actions.setlastTmpSaveTime(response?.last_tmp_save_time)
        );
      } else {
        onError && onError();
      }
    } catch (error) {
      onError && onError();
      dispatch(slice.actions.hasError(error));
    }
    callback && callback();
  };
}
// from表单搜集的甲部资料
export function getFormparty(params) {
  return (dispatch) => {
    try {
      const response = params.values;
      dispatch(slice.actions.getfirstParty(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// from表单搜集的已部资料
export function getPartB(params) {
  return (dispatch) => {
    try {
      const response = params.values;
      dispatch(slice.actions.getfirstPartB(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// from表单搜集的丙部资料
export function getPartC(params) {
  return (dispatch) => {
    try {
      const response = params.values;
      dispatch(slice.actions.getfirstPartC(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// 收集选择报读的年级
export function getSchoolYearDate(params) {
  return (dispatch) => {
    try {
      const response = params;
      dispatch(slice.actions.getSchoolYear(response.item));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// 提交表单数据
export function fetchsubmitForm({ callback, onError, ...params }) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await submitForm(params);
      callback(response);
      dispatch(slice.actions.fetchSubmit(response));
    } catch (error) {
      onError(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// 获取後補資料的表單項
export function fetchSubForm({ onError, ...params }) {
  return async (dispatch) => {
    try {
      const response = await getSubForm(params);
      if (Array.isArray(response?.form_item_list)) {
        dispatch(slice.actions.getSections(response));
        dispatch(slice.actions.setFormTask(response));
      } else {
        onError && onError();
      }
    } catch (error) {
      onError && onError();
      dispatch(slice.actions.hasError(error));
    }
  };
}

// auto save form data
export function fetchAutoSaveData({
  successCallback,
  errorCallback,
  ...params
}) {
  return async (dispatch) => {
    try {
      const response = await saveTemeForm(params);
      dispatch(slice.actions.setAutoSaveForm(response));
      successCallback();
    } catch (error) {
      errorCallback();
      dispatch(slice.actions.setAutoSaveForm(error.result));
      // dispatch(slice.actions.hasError(error));
    }
  };
}
