/* eslint-disable no-unused-vars */
import React, { Suspense } from 'react';
// import { useLocation } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';

const Loadable = (Component) => (props) =>
  (
    // const { pathname } = useLocation();
    // const isDashboard = pathname.includes('/dashboard');

    // <Suspense fallback={<>正在加载...</>}>
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
export default Loadable;
