import { Client } from 'aliyun-gateway-api';
import APP from './config/app';
import { getKeyByValue } from './utils/helper';
import APICode from './config/api';
import uuid from './utils/uuid';
import { saveAs } from 'file-saver';
import { store } from '@/redux/store';

// import store from '@/redux/store';
// import { logout } from 'slice/auth';

const axios = require('axios');

const isLocalhost = (url) => {
  const host = new URL(url).hostname;

  return Boolean(
    host === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      host === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      host.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );
};

let _successCallback = function (response) {
  console.log(response);
  if (
    typeof response.data !== 'undefined' &&
    !!('result' in response.data) &&
    parseInt(response.data.result) === 1
  ) {
    return response.data.data || response.data.result;
  } else {
    if (response.data.result === 24) {
      // store.dispatch(logout());
    }
    throw !!('result' in response.data) ? response.data : false;
  }
};
let _errorCallback = function (error) {
  console.log(error);

  let _msg = {
    result: 0,
    code: undefined,
    msg: undefined,
    data: undefined,
  };
  if (
    error !== false &&
    ['object', 'array'].indexOf(typeof error) > -1 &&
    !!('result' in error)
  ) {
    var code = getKeyByValue(APICode, error.result);
    _msg = {
      result: error.result,
      code: code || undefined,
      msg: !!code ? code || undefined : undefined, // ($rootScope.lang().api)[code]
      data: typeof error.data !== 'undefined' ? error.data : undefined,
    };
  } else {
    console.error(error);
  }
  throw _msg;
};

let _client = new Client(APP.APP_SETTINGS.AppKey, APP.APP_SETTINGS.AppSecret);

_client._get = _client.get;
_client._post = _client.post;

_client.uploadOSS = function (_permit, file) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', function (e) {
      if (e.lengthComputable) {
        //        console.log(e.loaded / e.total);
      }
    });
    xhr.upload.addEventListener('load', function () {
      //      console.log("uploaded");
    });
    xhr.onreadystatechange = function () {
      // Call a function when the state changes.
      if (this.readyState === XMLHttpRequest.DONE) {
        const ret = { code: this.status };
        if (this.status === 200) {
          ret.data = xhr.response;
          resolve(ret);
        } else {
          reject(ret);
        }
      }
    };
    xhr.open('PUT', _permit.sign_url);
    xhr.setRequestHeader('x-oss-callback', _permit['x-oss-callback']);
    xhr.overrideMimeType(file.type);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.responseType = 'json';
    xhr.send(file);
  })
    .then(_successCallback)
    .catch(_errorCallback);
};

_client.get = function (_opt, cache) {
  if (_opt && isLocalhost(_opt)) {
    _opt = _opt
      .replace(
        `${process.env.REACT_APP_BASE_URL}`,
        `${process.env.REACT_APP_BASE_URL}/api`
      )
      .concat('.json');

    console.log(_opt);

    return axios
      .get(_opt, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => response.data.data);
  }
  return _client._get(_opt).then(_successCallback).catch(_errorCallback);
};
_client.download = function (opt) {
  return axios
    .get(opt.url, {
      headers: {
        ...opt.headers,
        'Device-Id': getUUID(),
      },
      responseType: 'blob',
    })
    .then((p) => !p.data.result && saveAs(p.data, opt.fileName))
    .catch(_errorCallback);
};
_client.post = function (opt) {
  const { getState } = store;

  const {
    user: { access_token, device_id },
  } = getState();

  if (!!opt && opt.url && isLocalhost(opt.url)) {
    return _client.get(opt.url);
  }
  let _opt = {
    ...opt,
    form: {
      lang: getLang(),
      // device_id: (!!opt.form && opt.form.device_id) || device_id || getUUID(),
      // access_token: (!!opt.form && opt.form.access_token) || getUToken() || access_token,
      device_id: (!!opt.form && opt.form.device_id) || device_id || getUUID(),
      access_token:
        (!!opt.form && opt.form.access_token) || getUToken() || access_token,
      merchant_id: (!!opt.form && opt.form.merchant_id) || getMID(),
      ...opt.form,
    },
  };

  if (opt.form instanceof FormData) {
    opt.form.append('device_id', getUUID());
    return axios.post(opt.url, opt.form);
  }
  //  console.log("api-post", _opt);
  return _client._post(_opt).then(_successCallback).catch(_errorCallback);
};

export const client = _client;

export function setLang(i_lang) {
  return setLocal('lang', i_lang);
}

export function getLang() {
  let ret = getLocal('i18nextLng') || 'zh';
  ret = ret === 'zh-CN' ? 'zh' : ret;
  return ret;
}

export function getMID() {
  let ret = getLocal('merchant_id') || undefined;
  return ret;
}

export function setMID(i_token) {
  return setLocal('merchant_id', i_token);
}

export function setUToken(i_token) {
  return setLocal('access_token', i_token);
}

export function getUToken() {
  let ret = getLocal('access_token') || undefined;
  return ret;
}

export function getUUID() {
  const _UUID = getLocal('uuid') || uuid();
  if (getLocal('uuid')) {
    return _UUID;
  }
  setLocal('uuid', _UUID);
  return _UUID;
}

function getLocal(key) {
  return localStorage.getItem(key);
}

export function setLocal(key, value) {
  if (!(value || '').trim()) {
    return delLocal(key);
  } else {
    localStorage.setItem(key, value);
  }
  return localStorage.getItem(key);
}

function delLocal(key) {
  localStorage.removeItem(key);
  return !localStorage.getItem(key);
}

const ret = {
  client,
};

export default ret;
