const config = {
    select_time: 'Interview Time selected',
    please_select: 'Interview Information',
    interview_date: 'Interview Time',
    interview_stu: 'Name of Interview Student',
    interview_name: 'Name of Interview Campus',
    Application_No: 'Application Number',
    print: 'Print',
    go_home: 'HOME',
    confirm_date: 'Confirm the Interview Time',
    reselect: 'Reselect',
    confirm: 'Confirm',
    // Gabriel End Here 29-May-2023
};
export default config;
