/* eslint-disable quotes */
const config = {
  change_print_data: 'Check or Change Information/Print Form',
  next_step: 'Next',
  go_back: 'Back',
  apply_child_name: 'Name of Child Applicant',
  Birthdate: 'Date of Birth',
  Certificate: 'Child\'s Hong Kong Birth Certificate No.',
  orther: 'Others（Non-local Birth Certificate No. or Passport No.）',
  registration_number: 'e.g. A123456(7), please enter: A1234567 ',
  correct: 'Incorrect format',
  E_mail: 'Email Address',
  phone_or_email: 'Contact Tel/Email',
  required: 'Required',
  err_email: 'Please enter a valid email',
  login_err: 'Login Failure',
  login_err210: 'No results',
  login_suc: 'Login Success',
  apply_tip: 'Incorrect login credentials',
  school_name: 'School name',
  apply_num: 'Application Number',
  apply_year: 'Application Level',
  receipt: 'Receipt',
  interview_date: 'Interview Date',
  form_task: 'Outstanding Documents',
  apply_status: 'Payment status',
  operation: 'Operation',
  open: 'Open',
  look: 'View',
  receive_apply: 'Application Received',
  change: 'Change',
  change_end: 'Change period has expired',
  logout: 'Logout',
  other_school: 'Apply Other Schools',
  enter_link: 'Enter the Questionnaire Page',
  apply_title:
    'We have received an application for admission to {{grade}} from applicant {{name}} and an interview has been scheduled. Details are as follows: ',
  address:
    'On the day of the interview, please have a parent/guardian accompany your child to arrive 10 minutes prior to the interview and register at the reception desk of "Liang Wang Peifang Hall" on the ground floor of "Baoliang Kuzhuang Qicheng Building" and present the following documents.',
  download_attachment: 'Download Attachment',
  print: 'Print',
  date: 'Date',
  time: 'Time',
  please_pay_attention: 'Please Note',
  attention_1:
    'The interview time cannot be changed. If you are absent on the interview day and wish to apply for a post-interview, you must email the school to make an application.',
  aattention_2:
    'If you are not able to attend the interview due to physical discomfort, you must submit a medical certificate to the school and the school will inform you about the arrangement of the post-interview later.',
  attention_3:
    'Late arrivals will not be scheduled for a follow-up interview until the school has completed interviews with all parents and children who arrived on time.',
  attention_4:
    'If you have not yet paid the application fee, you can pay in cash on the day of the interview.',
  View: 'View',
  immediatePayment: 'Pay',
  Info: 'Information',
  DocumenID: 'Passport Number or Other Document Number',
  Norecord: 'No Records',
  declaration: 'I have read and accepted the ',
  Statement: 'Personal Data Collection Declaration',
  select_time: 'Select the Interview Time',
  BankSlip: 'Bank Slip',
  // Gabriel End Here 29-May-2023
  recaptcha_error: 'Invalid recaptcha verify',
};
export default config;
