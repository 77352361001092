const config = {
  change_print_data: '查阅或更改资料/列表表格',
  next_step: '下一步',
  go_back: '返回',
  apply_child_name: '申请幼儿姓名',
  Birthdate: '出生日期',
  Certificate: '幼儿的香港出生证明书号码',
  orther: '其他（非本地出生证明书号码或护照号码）',
  registration_number: '如：A123456(7)，请输入：A1234567',
  correct: '格式错误',
  E_mail: '电邮地址',
  phone_or_email: '联络电话/电邮地址',
  required: '必须填写',
  err_email: '请输入有效电子邮件地址',
  login_err: '登入失败',
  login_err210: '没有资料',
  login_suc: '登入成功',
  apply_tip: '登入资料不正确',
  school_name: '学校名称',
  apply_num: '申请编号',
  apply_year: '申请年级',
  receipt: '收据',
  interview_date: '面见日期',
  form_task: '后补资料          ',
  apply_status: '付款状态',
  operation: '操作',
  open: '打开',
  look: '查看',
  receive_apply: '已收申请',
  change: '更改',
  change_end: '更改期已届满',
  logout: '登出',
  other_school: '报读其他学校',
  enter_link: '进入问卷页',
  apply_title:
    '本幼稚园收到申请人{{name}}入读{{grade}}的申请，并已获安排面试时间。详情如下：',
  address:
    '面试当日请由一位家长/监护人陪同幼儿于十分钟前到达，到「保良局庄启程大厦地下梁王培芳堂接待处」登记，并出示以下文件。',
  download_attachment: '下载附件',
  print: '列印',
  date: '日期',
  time: '时间',
  please_pay_attention: '请注意',
  attention_1:
    '面试时间不能更改，如因面试当天缺席而欲申请後补面试，需电邮致学校作申请。',
  attention_2:
    '如因身体不适而未能出席面试，需向学方提交医生证明书，学方稍後会通知有关后面试的安排。',
  attention_3:
    '迟到者要待校方完成当日所有准时到达的家长和小朋友的面试，才会被安排当日的后补面试时间。',
  attention_4: '如尚未支付报名费，可于面试当日以现金支付。',
  View: '查看',
  immediatePayment: '立即付款',
  Info: '资讯',
  DocumenID: '护照号码或其他证件号码',
  Norecord: '没有资料',
  declaration: '本人已阅读及接受',
  Statement: '维多利亚教育机构的私隐政策声明',
  select_time: '按此选择面试时间',
  BankSlip: '报名费入数纸',
  // Gabriel End Here 29-May-2023
  recaptcha_error: '验证错误',
};
export default config;
