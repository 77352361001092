/* eslint-disable camelcase */
import { client } from 'lib/evi-api-gateway';

const url = `${process.env.REACT_APP_API_URL}/form_task`;

// get form task list
export async function getFormTaskList({
  app_grade_id,
  email,
  cert_type,
  cert_id,
  birthday,
}) {
  return client.post({
    url: `${url}/get_list`,
    form: {
      app_grade_id,
      email,
      cert_type,
      cert_id,
      birthday,
    },
  });
}

// get current form task
export async function getNsUserForm({
  app_grade_id,
  email,
  cert_type,
  cert_id,
  birthday,
  id,
}) {
  return client.post({
    url: `${url}/get_ns_user_form`,
    form: {
      app_grade_id,
      email,
      cert_type,
      cert_id,
      birthday,
      id,
    },
  });
}

// submit form task data
export async function submitNsUserForm({
  app_grade_id,
  cert_type,
  cert_id,
  birthday,
  email,
  id,
  token,
  form_item,
}) {
  return client.post({
    url: `${url}/submit_form`,
    form: {
      app_grade_id,
      cert_type,
      cert_id,
      birthday,
      email,
      id,
      token,
      form_item,
    },
  });
}

export default {
  getFormTaskList,
  getNsUserForm,
  submitNsUserForm,
};
