import { createSlice } from '@reduxjs/toolkit';

// api
import { getSchoolInfo } from '../../api/main';

const initialState = {
  isLoading: false,
  info: {},
};

const slice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    getSchoolInfoSuccess(state, action) {
      state.isLoading = false;
      state.info = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;
export const { setInterviewInfo } = slice.actions;
// ----------------------------------------------------------------------
export function getSchoolInfoAction(parms) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getSchoolInfo(parms);
      dispatch(slice.actions.getSchoolInfoSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
