import { client } from 'lib/evi-api-gateway';

const url = `${process.env.REACT_APP_API_URL}/app_master`;

// 获取选择报读学年列表
export async function getSelectYear({ schoolId }) {
  return client.post({
    url: `${url}/get_app_list`,
    form: {
      school_id: schoolId,
    },
  });
}
// 获取选择報讀的幼稚園列表
export async function getSchoolAppYear(params) {
  return client.post({
    url: `${url}/get_school_app_list`,
    form: {
      ...params,
    },
  });
}

export default {
  getSelectYear,
  getSchoolAppYear,
};
