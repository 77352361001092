const config = {
  select_school_year: '報讀年級',
  enter_basic_info: '基本資料',
  please_select_kindergarten: '報讀幼稚園',
  select_grade: '請選擇年級',
  please_enter_register_form: '報名表格',
  confirm_and_payment: '確認及付款',
  complete: '完成',
  apply_school_year: '申請報讀學年',
  freshman_apply: '新生入學申請(2022-2023)',
  select_apply_grade: '選擇申請年級',
  select_kindergarten: '選擇報讀的幼稚園',
  enter_register_form: '填寫入學報名表格',
  next_step: '下一步',
  last_step: '上一步',
  go_back: '返回',
  previous_page: '上一頁',
  confirm: '確定',
  provide: '(提供 {{time}})',
  provides: '提供',
  Not_open: '(未開放)',
  none: '沒有紀錄',
  tip_select_grade: '請選擇欲報讀的幼稚園',
  leave_page: '是否確定離開此頁面？',
  first_verify_email: '首次使用需驗證你的電郵地址',
  code_sened: '驗證碼已發送到',
  resen_code: '重新發送驗證碼',
  verify_suc: '驗證成功',
  verify_err: '驗證失敗，請輸入正確的驗證碼',
  // Gabriel End Here 29-May-2023
  not_edit: '已截止更改',
};
export default config;
