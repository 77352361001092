import { createSlice } from '@reduxjs/toolkit';

import * as auth from 'api/auth';
import * as user from '../../api/user';

const initialState = {
  // access_token: localStorage.getItem('access_token') || '',
  // 登录token
  access_token: '',
  // 设备id
  device_id: '',
  isEditChoice: false, // 是否报读其他学校,
  notificationList: {
    total: '',
    rows: [],
  },
  noticeLoading: false,
  isLoading: false,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      // state.isLoading = true;
      state.error = action.payload;
    },

    // 设置token
    setAccessToken(state, action) {
      state.isLoading = false;

      state.access_token = action.payload;
      // localStorage.setItem('access_token', action.payload);
    },
    // 设置设备id
    setDeviceId(state, action) {
      state.device_id = action.payload;
    },

    // 清空token
    clearAccessToken(state) {
      state.access_token = '';
      // localStorage.removeItem('access_token');
    },
    // 清空设备id
    clearDeviceId(state) {
      state.device_id = '';
    },
    setIsEditChoice(state) {
      state.isEditChoice = true;
    },
    clearIsEditChoice(state) {
      state.isEditChoice = false;
    },
    setNoticeLoading(state, { payload }) {
      state.noticeLoading = payload;
    },
    setMsgList(state, { payload }) {
      return {
        ...state,
        noticeLoading: false,
        notificationList: payload
      }
    },
  },
});

export const { setNoticeLoading } = slice.actions;

export function fetchLogin({ callback, onError, ...params }) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await auth.login(params);
      dispatch(slice.actions.setAccessToken(response));
      if (callback) {
        callback();
      }
    } catch (error) {
      if (onError) {
        onError();
      }
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMsgLists(params) {
  return async (dispatch) => {
    try {
      const response = await user.getMsgList(params);
      dispatch(slice.actions.setMsgList(response));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.setNoticeLoading(false));
    }
  };
}

// Actions
export const {
  setAccessToken,
  clearAccessToken,
  setDeviceId,
  clearDeviceId,
  setIsEditChoice,
  clearIsEditChoice,
} = slice.actions;

export default slice.reducer;
// ----------------------------------------------------------------------
