/* eslint-disable no-unused-vars */
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// material
// import { enUS, zhCN, zhHK } from '@mui/material/locale';
import { enUS, zhHK, zhCN } from '@mui/material/locale';

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: '中文繁體',
    value: 'zh',
    systemValue: zhHK,
    rename: 'zh',
  },
  {
    label: '中文简体',
    value: 'cn',
    systemValue: zhCN,
    rename: 'cn',
  },
  {
    label: 'English',
    value: 'english',
    systemValue: enUS,
    rename: 'en',
  },
];

export default function useLocales() {
  const { i18n, t } = useTranslation();

  const { info } = useSelector((state) => state.main);

  // set default lang
  const allLang = info.support_lang
    ? info.support_lang.reduce(
        (acc, cur) => acc.concat(LANGS.find((item) => item.value === cur)),
        []
      )
    : LANGS;

  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang =
    LANGS.find((_lang) => _lang.value === langStorage) ||
    info?.default_lang ||
    'zh';
  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    t,
    currentLang,
    allLang,
  };
}
