import React from 'react';
// material
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import useLocales from '../hooks/useLocales';
//
import breakpoints from './breakpoints';

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function ThemeConfig({ children }) {
  // 简体时切换字体
  const { currentLang } = useLocales();

  const themeConfig = createTheme({
    typography: {
      fontFamily: currentLang.value === 'cn' ? 'Noto Sans SC' : 'Noto Sans TC',
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
    },
    breakpoints,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeConfig}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
